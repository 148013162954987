import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import CountryFlag from 'react-country-flag';
import getCountries from 'react-select-country-list';
import {
    navigateTo,
    getPathAndQueryParams,
    styleWrapper,
    toggleErrorDisplay,
} from '../util/generic'

import '../components_styles/signup.css'

const SignUpPage = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [emailCode, setEmailCode] = useState('');
    const [inputStatus, setInputStatus] = useState({
        firstNameTest: false,
        lastNameTest: false,
        emailTest: false,
        passwordTest: false,
    });
        //countries dropdown state variables
    const [countries, setCountries] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchActive, setSearchActive] = useState(false);
    const [selectedCountry,] = useState('');
    
    const [signupButtonStatus, setSignupButtonStatus] = useState(false);


    //references
    const showPassword = useRef(null);
    const hidePassword = useRef(null);
    const passwordToggle = useRef(null);
    const passwordInput = useRef(null);
    const countryDropdownSearchbar = useRef(null);
    const countrySelected = useRef(null);
    const subForm = useRef(null);
    const emailInputError = useRef(null);
    const passwordInputError = useRef(null);

    const clientArea='/client-area'

    const criteria = {
        specialChar: /[$&+,:;=?@#|'<>.^*()%!-]/,
        length: /^.{8,30}$/,
        uppercase: /[A-Z]/,
        lowercase: /[a-z]/,
        number: /\d/
    };

    //useEffect method
    useEffect(()=>{
        //make the html element the browser's height
        document.documentElement.style.minHeight= window.innerHeight+"px";
        document.documentElement.style.backgroundColor= "#1F1F1F";

        showPassword.current.style.display = "none";
        //countries dropdown data initialization
        const countriesData = getCountries().getData();
        setCountries(countriesData); 
    }, [selectedCountry]);

        

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };
    const handleCodeChange = (e) => {
        setEmailCode(e.target.value);
    };

    const validCharsRegex = /^[a-zA-Z'-]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    
    // Function to update inputStatus object
    const updateInputStatus = (key, value) => {
        setInputStatus(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

        //This function switches between a visible password and a non-visible password when the password hider/displayer is clicked
    const togglePasswordView=(e)=>{
        // Toggle back and forth
        //console.log(e.target.nextElementSibling)
        let hidePasswordBlob= hidePassword.current
        let showPasswordBlob= showPassword.current
        let parentInput = passwordInput.current
        //console.log(parentInput)

        if (showPasswordBlob.style.display === "none") {
            hidePasswordBlob.style.display="none";
            showPasswordBlob.style.display="block";

            parentInput.setAttribute("type","text")                
        } else {
            showPasswordBlob.style.display="none";
            hidePasswordBlob.style.display="block";

            parentInput.setAttribute("type","password")                
        }
    }

    //Used to display the list of password criteria matched and unmatched
    const validatePassword = (elem) =>{
        const password = elem.value;
        //setNewPassword(password)
        //console.log(password)
        const validationList = document.querySelector('.validation-list');
        validationList.innerHTML = ''; // Clear previous results
        
        const validationResults = {
            specialChar: criteria.specialChar.test(password),
            length: criteria.length.test(password),
            uppercase: criteria.uppercase.test(password),
            lowercase: criteria.lowercase.test(password),
            number: criteria.number.test(password)
        };

        const validationDisplay = {
            specialChar:"At least one special character",
            length: "Between 8-30 characters",
            uppercase: "One upper case character",
            lowercase: "One lower case character",
            number: "At least one number"
        };

        for (const [key, value] of Object.entries(validationResults)) {
            const listItem = document.createElement('li');
            
            if (value) {
                listItem.style.listStyleType = "none";
                listItem.innerHTML = `<span>&#10003;</span> ${validationDisplay[key]}`;
                listItem.style.color = 'green';
            } else {
                listItem.innerHTML = `${validationDisplay[key]}`;
                listItem.style.color = 'red';

                // hightlighting the passwordWrapper border
                elem.parentElement.style.border="1px solid #ce0000"
                
                //Make the password test false
                //updateInputStatus('passwordTest', false)
            }
            validationList.appendChild(listItem);
        }
    }

    //Used to know if after the long run, the password is still valid
    function isValidPassword(password) {
        const { specialChar, length, uppercase, lowercase, number } = criteria;
      
        // Check if the password contains a special character
        if ((specialChar.test(password)) && (length.test(password)) && (uppercase.test(password)) && (lowercase.test(password)) && (number.test(password))) {
            return true
        }else{
            return false
        }
    }
      
    //Used to know if all values of an object is true
    const areAllTrue = (obj) => {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (!obj[key]) {
                    return false; // Return false if any value is false
                }
            }
        }
        return true; // Return true if all values are true
    }

    const postFocus = (e) => {  
        let inputLabel = e.target.parentElement.firstElementChild; // input's label
        let legendElem = inputLabel.nextElementSibling   //legend element
        let errorDisplay= e.target.parentElement.parentElement.lastElementChild; //input's error display
        let inputWrapper=  e.target.parentElement; //input's wrapper
        inputLabel.style.display = "none";
        legendElem.style.display = "block"; 

        if(e.target["id"] === "country"){
            errorDisplay.style.display="none";
            //No filling for this placeholder
            //display the drop down
            let dropdownElem= e.target.parentElement.parentElement.nextElementSibling;
            //dropdownElem.style.display="block";

            //focus the search element
            if(!searchActive){
                dropdownElem.style.display= "block";
                countryDropdownSearchbar.current.focus()
                setSearchActive(true)
                e.target.blur()
            }
            else{//If dropdown is active
                dropdownElem.style.display= "none";
                setSearchActive(false)
                e.target.blur()
                //check if a country has been selected and display error if necessary
                if (!countrySelected.current.textContent){
                    inputLabel.style.display="flex"
                    legendElem.style.display="none"
                //display an error
                    errorDisplay.textContent = "Please select a country."
                    errorDisplay.style.display = "block"
                    inputWrapper.style.border="1px solid #ce0000"
                }
                else{
                    inputWrapper.style.border="1px solid var(--valid-input-border)"
                }
            }
        }
        else{
            e.target.setAttribute("placeholder",`${inputLabel.textContent}`) //filling placeholder
        }

        /*
            listen out for password input;
        */
        if(e.target["id"] === "password"){
            //listens out for an input
            e.target.oninput=(e)=>{
                //hiding the error output if it displayed earlier
                e.target.parentElement.parentElement.lastElementChild.style.display="none"

                //checking for password validation to update the list
                validatePassword(e.target)

                //To know when the password is valid
                const password=e.target.value
                const isValid = isValidPassword(password);
                if (isValid){
                    setPassword(password)
                    updateInputStatus('passwordTest', true)
                    e.target.parentElement.style.border="1px solid var(--valid-input-border)"
                }
                /*
                */
            }
        }

        e.target.onblur = (e) => {
            let inputValue= e.target.value.trim();
            let err=false; //error indicator 

            //empty inputs
            switch(e.target["id"]){
                case "first-name":
                    if(inputValue===""){
                        displayError('Please enter your first name');
                    }else if(!validCharsRegex.test(inputValue)) {
                        displayError('Name should contain only letters, hyphens, and apostrophes.');
                    }else if (inputValue.length < 2 || inputValue.length > 50) {
                        displayError('Name length should be between 2 and 50 characters.');
                    }
                    else{
                        updateInputStatus('firstNameTest', true)
                        //console.log(inputStatus)
                    }
                break;
                case "last-name":
                    if(inputValue===""){
                        displayError('Please enter your last name');
                    }else if (!validCharsRegex.test(inputValue)) {
                        displayError('Name should contain only letters, hyphens, and apostrophes.');
                    }else if (inputValue.length < 2 || inputValue.length > 50) {
                        displayError('Name length should be between 2 and 50 characters.');
                    }else{
                        updateInputStatus('lastNameTest', true)
                        //console.log(inputStatus)
                    }
                break;
                case "email":
                    if(inputValue===""){
                        displayError('Please enter a valid email address');
                    }else if (!emailRegex.test(inputValue)) {
                        displayError('Please enter a valid email address');
                    }
                    else{
                        updateInputStatus('emailTest', true)
                        //console.log(inputStatus)
                    }
                    //Backend check   
                break;
                case "password":
                    if(inputValue===""){
                        displayError('Please enter a strong password combination');
                    }
                    //Backend check   
                break;
                default:
                break;
            
            }

            //error display function
            function displayError(errMessage){
                if (inputValue===""){
                    inputLabel.nextElementSibling.style.display = "none"; //legend element
                    e.target.setAttribute("placeholder","") //emptying placeholder
                    inputLabel.style.display = "block";
                }

                //display error messages and styles
                errorDisplay.textContent = errMessage
                errorDisplay.style.display = "block"
                inputWrapper.style.border="1px solid var(--err-input-border)"

                //indicator that an error occured
                err=true
            }

            //executes when no error occurs after inputting
            if(!err && e.target["id"] !== "password"&& e.target["id"] !== "country"){
                inputWrapper.style.border="1px solid var(--valid-input-border)"
                errorDisplay.style.display= "none"
            }
        }
    }

    const handleCountrySelect = country => {
        //empty the searchTerm variable
        setSearchTerm('');
        // display the subform
        subForm.current.style.display = "block"
    };


    //const that hold the value of filtered countries when the search bar is active
    const filteredCountries = searchTerm
    ? countries.filter(country =>
        country.label.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : countries;

    const toggleLoader = (selector,display,status) =>{
        document.querySelector(selector).firstElementChild.style.display=display;
        if (display==="none"){
            document.querySelector(selector).style.filter="grayscale(0)"
        }else{
            document.querySelector(selector).style.filter="grayscale(1)"
        }
        setSignupButtonStatus(status)
    }

    const handleSubmit= (e)=>{
        if(areAllTrue(inputStatus) && countrySelected.current.textContent && !signupButtonStatus){
            //display the loader
            e.currentTarget.firstElementChild.style.display="block";
            e.currentTarget.style.filter="grayscale(1)"
            setSignupButtonStatus(true)//probably make it false when you've finished the task
            
            //Check that the email address doesn't exist
            emailAdressExist(e)

        }else if(!areAllTrue(inputStatus)){
        }
    }

    const handleCodeCheck=()=>{
        if(!signupButtonStatus){
            //Hide the error initially displayed
            toggleErrorDisplay(
                ['.email-code-input-error'],
                [''],
                ['none']
            )
            //display the loader and set the signup button state to true
            toggleLoader('.code-check-button','block',true)

            if(emailCode===''){//Display an error when no code is entered
                toggleErrorDisplay(
                    ['.email-code-input-error'],
                    ['Please input the required code.'],
                    ['block']
                )
                //remove the loader and set the signup button state to false
                toggleLoader('.code-check-button','none',false)
            }else{//Call the ConfirmEmailVerification code function
                confirmEmailVerificationCode()
            }
        }
    }

    const emailAdressExist = async () => {
        try {
            const url = window.location.href.includes("localhost")
            ? "http://localhost:8000/coinstackcapital/authenticate-email/"
            : "https://noapiere.pythonanywhere.com/coinstackcapital/authenticate-email/";
            await axios.post(`${url}`, {
                email:email,
            });

            //Get hold of the email input wrapper
            const emailInputWrapper=emailInputError.current.parentElement.firstElementChild
            //Display a text in the email error input 
            emailInputError.current.textContent = `The email address ${email} exists.`
            emailInputError.current.style.display = "block"
            emailInputWrapper.style.border="1px solid var(--err-input-border)"

            //hide the loader
            setSignupButtonStatus(false)
            document.querySelector(".pre-signup-button").firstElementChild.style.display="none";
            document.querySelector(".pre-signup-button").style.filter="grayscale(0)" 
        } catch (error) {
            if (error.response){
                if (error.response.status === 404){//The email does not exist
                    //The email does not exist
                    //send the email verification code
                    sendEmailVerificationCode()   
                }else{
                    console.log("An error occured")
                }
            }
        }
    };

    const sendEmailVerificationCode = async () => {
        //calling this function assumes that all inputs are valid
        //make input wrappers have valid border and remove their errors
        const emailInputWrapper=emailInputError.current.parentElement.firstElementChild
        emailInputWrapper.style.border="1px solid var(--valid-input-border)"
        passwordInputError.current.style.display = "none"
        emailInputError.current.style.display = "none"

        try {
            const url = window.location.href.includes("localhost")
            ? "http://localhost:8000/coinstackcapital/send-email-verification-code/"
            : "https://noapiere.pythonanywhere.com/coinstackcapital/send-email-verification-code/";
            await axios.post(`${url}`, {
                email:email,
                first_name:firstName,
                last_name:lastName
            });

            //set the SignupButton to false and hide the loader
            setSignupButtonStatus(false)
            document.querySelector(".pre-signup-button").firstElementChild.style.display="none";
            document.querySelector(".pre-signup-button").style.filter="grayscale(0)"  

            //navigate to email code section
            document.querySelector(".signup-pre-code-form").style.display="none"
            document.querySelector(".email-verification-code").style.display="block"

        } catch (error) {
            //An error occured while trying to send the code
            passwordInputError.current.textContent = 'An error occured. Check your internet connection and try again later.'
            passwordInputError.current.style.display = "block"

            //set the SignupButton to false and hide the loader
            setSignupButtonStatus(false)
            document.querySelector(".pre-signup-button").firstElementChild.style.display="none";
            document.querySelector(".pre-signup-button").style.filter="grayscale(0)"  
        }
    };

    const confirmEmailVerificationCode = async () => {
        try {
            const url = window.location.href.includes("localhost")
            ? "http://localhost:8000/coinstackcapital/confirm-email-verification-code/"
            : "https://noapiere.pythonanywhere.com/coinstackcapital/confirm-email-verification-code/";
            await axios.post(`${url}`, {
                email_code:emailCode
            });
            //style the email code wrapper
            styleWrapper(".email-code-input-wrapper","valid")

            //sign the user up
            signUserUp()

        } catch (error) {
            if (error.response && (error.response.status === 400)){//When the code was not found
                //When the code was not found
                //Display the error
                toggleErrorDisplay(
                    ['.email-code-input-error'],
                    [`${error.response.data.message}`],
                    ['block']
                )
                styleWrapper(".email-code-input-wrapper","error")

                //remove the loader and set the signup button state to false
                toggleLoader('.code-check-button','none',false)
            }else{//sth unknown; display the error
                toggleErrorDisplay(
                    ['.email-code-input-error'],
                    ['An error occured. Try again'],
                    ['block']
                )
                styleWrapper(".email-code-input-wrapper","error")

                //remove the loader and set the signup button state to false
                toggleLoader('.code-check-button','none',false)
            }
        }
    };

    const signUserUp = async () => {
        //Remove the error display whenever called
        toggleErrorDisplay(
            ['.email-code-input-error'],
            [''],
            ['none']
        )

        try {
            const url = window.location.href.includes("localhost")
            ? "http://localhost:8000/coinstackcapital/signup/"
            : "https://noapiere.pythonanywhere.com/coinstackcapital/signup/";
            const response = await axios.post(`${url}`, {
                email:email,
                password:password,
                first_name:firstName,
                last_name:lastName,
                country:countrySelected.current.textContent,
                referrer_code:/\/referral\?ref=[a-f0-9]{16}/.test(getPathAndQueryParams()) ? window.location.search.split("=")[1] : ""
            });


            //set jwt token to the local storage
            localStorage.setItem("access_token",`${response.data.access_token}`)
            localStorage.setItem("refresh_token",`${response.data.refresh_token}`)

            //navigate to the dashboard
            navigateTo(clientArea)
        } catch (error) {
            //style the code wrapper to neutral since this is not its buisness
            styleWrapper(".email-code-input-wrapper","neutral")

            //error.response.data.reason This contains the message from the server
            let errMessage=error.response.data.message
            let emailErrFormat= /The email address ([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}) exists\./
            if (emailErrFormat.test(errMessage)){//Display error
                //display the error on the last error box
                toggleErrorDisplay(
                    ['.email-code-input-error'],
                    [`${errMessage}`],
                    ['block']
                )

                //remove the loader and set the signup button state to false
                toggleLoader('.code-check-button','none',false)
            }else{//Something strange
                //display the error on the last error box
                toggleErrorDisplay(
                    ['.email-code-input-error'],
                    ['An error occured. Try again.'],
                    ['block']
                )
                
                //remove the loader and set the signup button state to false
                toggleLoader('.code-check-button','none',false)
            }
        }
    };


  return (
    <div>
        <div className="sk-top-bar">
            <a href="/">
                <h1 className="sk-top-header">Stack Financial</h1>
            </a>
            <div className="language-toggle-button" >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.35 16.92 6ZM10 2.04C10.83 3.24 11.48 4.57 11.91 6H8.09C8.52 4.57 9.17 3.24 10 2.04ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12H2.26ZM3.08 14H6.03C6.35 15.25 6.81 16.45 7.41 17.56C5.57 16.93 4.04 15.66 3.08 14ZM6.03 6H3.08C4.04 4.34 5.57 3.07 7.41 2.44C6.81 3.55 6.35 4.75 6.03 6ZM10 17.96C9.17 16.76 8.52 15.43 8.09 14H11.91C11.48 15.43 10.83 16.76 10 17.96ZM12.34 12H7.66C7.57 11.34 7.5 10.68 7.5 10C7.5 9.32 7.57 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12H14.36Z" fill="#ABABAB"/>
                </svg>
                <span className="ng-binding">English</span>
            </div>
        </div>
        <main>
            <div className="sub-header-wrapper">
                <h2>Registration</h2>
            </div>
            <div className="signup-form">
                <div className="signup-pre-code-form">
                    <div className="country-question">
                        What is your country / region of residence?
                    </div>
                    <div className="country-selection">
                        {/*Country form input*/}
                        <div className="country-wrapper">
                            <fieldset className="country-input-wrapper input-wrapper">
                                <label htmlFor="country">
                                    Country/Region of Residence
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                        <path d="M1.61508 0.295002L0.205078 1.705L6.20508 7.705L12.2051 1.705L10.7951 0.295002L6.20508 4.875L1.61508 0.295002Z" fill="#5E6368"/>
                                    </svg>
                                </label>
                                <legend>
                                    Country/Region of Residence
                                </legend>
                                <div ref={countrySelected} className="country-selected">
                                </div>
                                <input 
                                    type="text" 
                                    id="country"
                                    onFocus={postFocus}
                                    
                                /> 
                            </fieldset>
                            <div className="country-input-error input-error">
                                Please enter your country.
                            </div>
                        </div>
                        {/*Country selection drop down*/}
                        <div className="dropdown-wrapper">
                            <div className="search-input-wrapper">
                                <div></div>
                                <input
                                    type="text"
                                    placeholder="Search for a country"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    ref={countryDropdownSearchbar}
                                    //onBlur={(e)=>{e.target.parentElement.parentElement.style.display="none"}}
                                />
                            </div>
                            <ul className="country-list">
                                {filteredCountries.map(country => (
                                <li key={country.value} onClick={
                                    (e) => {
                                        countrySelected.current.innerHTML=e.target.innerHTML
                                        e.target.parentElement.parentElement.style.display="none"
                                        e.target.parentElement.parentElement.parentElement.firstElementChild.firstElementChild.style.border="1px solid var(--valid-input-border)"
                                        handleCountrySelect(country)
                                    }
                                }>
                                    <CountryFlag countryCode={country.value} svg style={{ marginRight: '10px' }} />
                                    {country.label}
                                </li>
                                ))}
                                {filteredCountries.length === 0 && <li>No matching countries found</li>}
                            </ul>
                        </div>                   
                    </div>
                    <div className="sub-form" ref={subForm}>
                        <div className="fullname-wrapper">
                            <div className="firstname-wrapper">
                                <fieldset className="firstname-input-wrapper input-wrapper">
                                    <label htmlFor="first-name">First name</label>
                                    <legend>First name</legend>
                                    <input type="text" id="first-name" value={firstName} onFocus={postFocus} onChange={handleFirstNameChange}/> 
                                </fieldset>
                                <div className="input-guide">As per your Passport / Government ID</div>
                                <div className="firstname-input-error input-error">
                                    Please enter your first name.
                                </div>
                            </div>
                            <div className="lastname-wrapper">
                                <fieldset className="lastname-input-wrapper input-wrapper">
                                    <label htmlFor="last-name">Last name</label>
                                    <legend>Last name</legend>
                                    <input type="text" id="last-name" value={lastName} onFocus={postFocus} onChange={handleLastNameChange}/> 
                                </fieldset>
                                <div className="input-guide">As per your Passport / Government ID</div>
                                <div className="last-input-error input-error">
                                    Please enter your last name.
                                </div>
                            </div>
                        </div>
                        <div className="email-wrapper">
                            <fieldset className="email-input-wrapper input-wrapper">
                                <label htmlFor="email">Email</label>
                                <legend>Email</legend>
                                <input type="email" id="email" value={email} onFocus={postFocus} onChange={handleEmailChange}/> 
                            </fieldset>
                            <div className="email-input-error input-error" ref={emailInputError}>
                            </div>
                        </div>

                        {/* form break*/}
                        <h3>
                            Create your account password
                        </h3>
                        <div className="password-wrapper">
                            <fieldset className="password-input-wrapper input-wrapper">
                                <label htmlFor="password">Account Password</label>
                                <legend>Account Password</legend>
                                <input 
                                    className="password-input input" 
                                    type="password" 
                                    id="password" 
                                    value={password} 
                                    onFocus={postFocus} 
                                    onChange={handlePasswordChange}
                                    ref={passwordInput}

                                /> 
                                <div 
                                    className="password-status-toggle"
                                    ref={passwordToggle}
                                    onClick={togglePasswordView}
                                >
                                    <span className="hide-password" ref={hidePassword}></span>
                                    <span className="show-password" ref={showPassword}></span>

                                </div>
                            </fieldset>
                            <ul className="validation-list">
                            </ul>
                            <div className="password-error input-error" ref={passwordInputError}>
                            </div>
                        </div>

                        <button type="button" onClick={e => handleSubmit(e)} className="signup-button pre-signup-button form-button">
                            <svg width="20" height="20" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop offset="0%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 1 }} />
                                        <stop offset="100%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 0 }} />
                                    </linearGradient>
                                </defs>
                                <circle cx="25" cy="25" r="20" stroke="url(#gradientStroke)" strokeWidth="4" fill="none" strokeLinecap="round"/>
                                <animateTransform
                                    attributeName="transform"
                                    attributeType="XML"
                                    type="rotate"
                                    from="0 0 0"
                                    to="360 0 0"
                                    dur="1s"
                                    repeatCount="indefinite"/>
                            </svg>
                            Continue
                        </button>

                        <p>
                            Based on the selected country of residence, you are registering with Stack Financial, 
                            regulated by the Seychelles FSA.By clicking Continue you confirm that you have read, 
                            understood, and agree with all the information in the Client Agreement.
                        </p>
                    </div>
                </div>
                <div className="email-verification-code">
                    <div className="evc-heading">
                        Verify your email address
                    </div>
                    <div className="evc-subheading">
                        An email with a verification code has been sent to <strong>{email}</strong>.<br/>
                        <span>Please also check your spam folder.</span>
                    </div>
                    <div className="email-code-input-wrapper input-wrapper">
                        <input className="input"
                            type="text"
                            value={emailCode}
                            onChange={handleCodeChange}
                            placeholder=""
                            id="email-code"
                        />
                    </div>
                    <div className="email-code-input-error input-error">
                        Please input a correct code.
                    </div>
                    <button type="button" onClick={e => handleCodeCheck(e)} className="signup-button code-check-button form-button">
                        <svg width="20" height="20" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop offset="0%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 1 }} />
                                    <stop offset="100%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 0 }} />
                                </linearGradient>
                            </defs>
                            <circle cx="25" cy="25" r="20" stroke="url(#gradientStroke)" strokeWidth="4" fill="none" strokeLinecap="round"/>
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                from="0 0 0"
                                to="360 0 0"
                                dur="1s"
                                repeatCount="indefinite"/>
                        </svg>
                        Continue
                    </button>
                </div>
            </div>
            <div className="already-have-an-account">Already have an account?</div>
            <div className="log-in">
                <a href="/signin">Log in</a>
            </div>
        </main>
        <div className="signup-footer-note">
            Trading involves a high degree of risk. Stack Financial Partners Limited is a registered 
            trading name in Seychelles (License Number SD064) which is authorized and regulated by the 
            Financial Services Authority with its company address at First Floor, Marina House, Eden Island, Republic of Seychelles.
        </div>
    </div>
  );
};


export default SignUpPage;
