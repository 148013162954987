import React from  'react';
import '../../components_styles/component_substyles/referral_style.css';

const ReferralComponent = ({refCode,totalEarnings,totalInvited,filterData}) => {
  
  const changeBackground = (button) => {
    let buttons = document.querySelectorAll(".rtha-sub");

    // Remove background color from all buttons
    for (var i = 0; i < buttons.length; i++) {
        buttons[i].classList.remove("default-rtha-sub");
    }
    
    // Set background color to white for the clicked button
    button.classList.add("default-rtha-sub");
  } 

  return (
    <div className="dashboard-sect referral-content" id="referral-content">
      <div className="referral-content-banner dashboard-sect-rcb">
        <div className="rcb-textual">
          <div className="banner-head rcb-heading">
            Refer friends. Earn Rewards. Trade. Repeat.
          </div>
          <div className="banner-note rcb-subtext">
            Share your friends a new experience
          </div>
          <div className="rcb-link-wrapper">
            <div>
              https://www.stackfinancialsolutions.com/path?ref={refCode}
            </div>
            <button>
              Copy Link
            </button>
          </div>
          <div className="banner-note rcb-bottom-text">
            By using this link, you are deemed to have agreed to the applicable Terms and Conditions.
          </div>
        </div>
        <div className="rcb-media"></div>
      </div>

      {/* Referral summary */}
      <div className="referral-count-header tab-head">
        <h3>Referal Summary</h3>
        <div className="tab-head-action rtha">
          <button onClick={(e) => {
              changeBackground(e.currentTarget);
              filterData('alltime')
            }} 
            className="rtha-sub rtha-all-time default-rtha-sub"
          >
            All Time
          </button>
          <button 
            onClick={(e) => {
              changeBackground(e.currentTarget);
              filterData('oneweek')
            }} 
            className="rtha-sub rtha-this-week"
          >
            This Week
          </button>
          <button onClick={(e) => {
              changeBackground(e.currentTarget);
              filterData('onemonth')
            }} 
            className="rtha-sub rtha-this-month"
          >
            This Month
          </button>
        </div>
      </div>
      <section className="account-summary referral-summary tab-body">
        <div className="account-summary-entry summary-entry">
          <div className="account-summary-note account-summary-note">
            <h4>Total Invited Friends</h4>
            <div>
              {totalInvited}
            </div>
          </div>
          <div className="total-invited-friends-icon token-icon"></div>
        </div>
        <div className="account-summary-entry summary-entry">
          <div className="account-summary-note summary-note">
            <h4>Rewarded Friends</h4>
            <div>
              {totalInvited}
            </div>
          </div>
          <div className="rewarded-friends-icon token-icon"></div>
        </div>
        <div className="account-summary-entry summary-entry">
          <div className="account-summary-note summary-note">
            <h4>Total Earning</h4>
            <div>${totalEarnings}</div>
          </div>
          <div className="total-earnings-icon token-icon"></div>
        </div>
        <div className="account-summary-entry summary-entry">
          <div className="account-summary-note summary-note">
            <h4>Rewards in Progress</h4>
            <div>$0</div>
          </div>
          <div className="rewards-in-progress-icon token-icon"></div>
        </div>
      </section>

      {/* Collect Badges and earn more */}
      <div className="account-summary-header tab-head">
        <h3>Collect Badges and Earn More</h3>
        <div className="tab-head-action rbht">
          <div className="rbht-sub rbht-noeb">
            Number of Earned Badges
          </div>
          <div className="rbht-sub ">
            {0} Badges
          </div>
        </div>
      </div>

      {/* Badge body */}
      <section className="account-summary tab-body">
        <div className="account-summary-entry referral-badge-entry">
          <div className="badge-icon bi-starter">
          </div>
          <div className="badge-heading">
            Referral Starter
          </div>
          <div className="badge-note">
            To earn this badge and get rewarded with $10 refer us to 5 of your friends.
          </div>
          <div className="badge-progress">
            <div className="bp-progress-bar"></div>
            <div className="bp-count">0/5</div>
          </div>
        </div>
        <div className="account-summary-entry referral-badge-entry">
          <div className="badge-icon bi-influencer">
          </div>
          <div className="badge-heading">
              Referral Influencer
          </div>
          <div className="badge-note">
              To earn this badge and get rewarded with $20 refer us to 10 of your friends.
          </div>
          <div className="badge-progress">{/*<!--badge progress-->*/}
              <div className="bp-progress-bar"></div>
              <div className="bp-count">0/10</div>
          </div>
        </div>
        <div className="account-summary-entry referral-badge-entry">
            <div className="badge-icon bi-expert">
            </div>
            <div className="badge-heading">Referral Expert</div>
            <div className="badge-note">
                To earn this badge and get rewarded with $30 refer us to 20 of your friends.
            </div>
            <div className="badge-progress">{/*<!--badge progress-->*/}
                <div className="bp-progress-bar"></div>
                <div className="bp-count">0/20</div>
            </div>
        </div>
        <div className="account-summary-entry referral-badge-entry">
            <div className="badge-icon bi-master">
            </div>
            <div className="badge-heading">Referral Master</div>
            <div className="badge-note">
                To earn this badge and get rewarded with $40 refer us to 30 of your friends.
            </div>
            <div className="badge-progress">{/*<!--badge progress-->*/}
                <div className="bp-progress-bar"></div>
                <div className="bp-count">0/30</div>
            </div>
        </div>
        <div className="account-summary-entry referral-badge-entry">
            <div className="badge-icon bi-champion">
            </div>
            <div className="badge-heading">
                Referral Champion
            </div>
            <div className="badge-note">
                To earn this badge and get rewarded with $100 refer us to 50 of your friends.
            </div>
            <div className="badge-progress">{/*<!--badge progress-->*/}
                <div className="bp-progress-bar"></div>
                <div className="bp-count">0/50</div>
            </div>
        </div>
        {/* ... Similar badge entries ... */}
      </section>
    </div>
  );
};

export default ReferralComponent;
