import React, { useState, useRef, useEffect} from 'react';
import axios from 'axios';
import '../components_styles/signin.css'
import PasswordWrapper from './passwordWrapper';
import {navigateTo} from '../util/generic'
//import {BrowserRouter as Link} from 'react-router-dom';

const SignInPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signinButtonStatus, setSigninButtonStatus] = useState(false);
  const [subFormStatus, setSubFormStatus] = useState(false);

  //references
  const emailInputError = useRef(null);
  const subForm = useRef(null);
  const emailInputWrapper = useRef(null);
  const passwordInputError = useRef(null)
  const passwordInputWrapperRef = useRef(null);

  //other constants
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const clientArea='/client-area/'

  useEffect(()=>{
    document.documentElement.style.minHeight= window.innerHeight+"px";
    document.documentElement.style.backgroundColor= "#F5F6F7";

    //set the email input to the value of the localStorage's email if it exists
    setEmail(localStorage.getItem('email_address'))
  },[])

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleInputFocus = (e) => {
    /*
    e.target.parentElement.style.border="1px solid var(--base-color)";
    e.target.onblur = (e) => {
        e.target.parentElement.style.border="1px solid #EBEBEB";
    }
    */
  }        
  
  const handleCheckBoxChange = (e) => {
    if (e.currentTarget.checked) {
        //console.log("Checkbox is checked");
        localStorage.setItem('email_address',email)
        // save the email address to the localStorage if it exists
      } else {
        localStorage.removeItem('email_address')
        //console.log("Checkbox is unchecked");
        // remove the email address from the localStorage if it exists
    }
  }



  const handleSignIn = async (e) => {
    // Add your sign-in logic here
    //console.log('Signing in with:', email, password);
    if((email==="" || !emailRegex.test(email)) && !signinButtonStatus){
      //update the status of the button
      setSigninButtonStatus(true)

      emailInputError.current.textContent='Please enter a valid email address';
      emailInputError.current.style.display="block";

      //update the status of the button
      setSigninButtonStatus(false)      
    }else{
      if(!signinButtonStatus && !subFormStatus){//Executes when the subform is not displayed
        //update the status of the button and display the loader
        setSigninButtonStatus(true)
        e.target.firstElementChild.style.display="block"
  
        //check if the email address exists
        try {
          const url = window.location.href.includes("localhost")
          ? "http://localhost:8000/coinstackcapital/authenticate-email/"
          : "https://noapiere.pythonanywhere.com/coinstackcapital/authenticate-email/";
          await axios.post(url, {
              email:email,
          });
  
          //erase any error if there was
          emailInputError.current.style.display="none";
  
          //style the email wrapper border
          emailInputWrapper.current.style.border="1px solid var(--valid-input-border)";
  
          //display the password input
          subForm.current.style.display="block"

          //update the status of the button and hide the loader
          setSigninButtonStatus(false)
          e.target.firstElementChild.style.display="none"

          //update the status of the subform display
          setSubFormStatus(true)
        } catch (error) {
            //error.response.data.reason This contains the message from the server
            //console.log(error.response.data.message)
  
            //erase any error if there was
            emailInputError.current.textContent=error.response.data.message;
            emailInputError.current.style.display="block";

  
            //style the email wrapper border
            emailInputWrapper.current.style.border="1px solid var(--err-input-border)";

            //update the status of the button and hide the loader
            setSigninButtonStatus(false)
            e.target.firstElementChild.style.display="none"
        }
      }else if(!signinButtonStatus && subFormStatus){//Executes when the subform is displayed
        //update the status of the button
        setSigninButtonStatus(true)

        //close each error display if they were previously displayed
        emailInputError.current.style.display="none";

        if(email==="" || !emailRegex.test(email)){//empty email value or invalid email value

          emailInputError.current.textContent='Please enter a valid email address';
          emailInputError.current.style.display="block";
          emailInputWrapper.current.style.border="1px solid var(--err-input-border)";
        }else if(password === ""){//empty password value

          //The email input should be filled now; so remove the error styling
          emailInputWrapper.current.style.border="1px solid var(--valid-input-border)";
          
          passwordInputError.current.textContent='Please enter your password';
          passwordInputError.current.style.display="block";
          passwordInputWrapperRef.current.style.border="1px solid var(--err-input-border)";
        }else if(password !== ""){//When the email and password values are both set

          //The email input should be filled now; so remove the error styling
          emailInputWrapper.current.style.border="1px solid var(--valid-input-border)";

          //update the status of the button and display the loader
          setSigninButtonStatus(true)
          e.target.firstElementChild.style.display="block"

          //Make a request to authenticate the details
          const url = window.location.href.includes("localhost")
          ? "http://localhost:8000/coinstackcapital/signin/"
          : "https://noapiere.pythonanywhere.com/coinstackcapital/signin/";
          try {
            const response = await axios.post(url, {
                email:email,
                password:password
            });
    
            //erase any error if there was and clear borders
            passwordInputError.current.style.display="none";
            emailInputWrapper.current.style.border="1px solid var(--valid-input-border)";
            passwordInputWrapperRef.current.style.border="1px solid var(--valid-input-border)";


            //Store the access token for future requests
            localStorage.setItem("access_token",`${response.data.access_token}`)
            localStorage.setItem("refresh_token",`${response.data.refresh_token}`)
    
            //update the status of the button and hide the loader; This is optional
            e.target.firstElementChild.style.display="none"

            //navigate to the dashboard page
            navigateTo(clientArea)
          } catch (error) {
              //error.response.data.reason This contains the message from the server
              //console.log(error.response.data.message)
              let errMessage=error.response.data.message
              let passwordErrFormat= /Please input a correct password./
              let emailPasswordErrFormat= /Email address or password is incorrect./

              //Style the input wrappers
              if (passwordErrFormat.test(errMessage)){
                passwordInputWrapperRef.current.style.border="1px solid var(--err-input-border)";
              }else if(emailPasswordErrFormat.test(errMessage)){
                emailInputWrapper.current.style.border="1px solid var(--err-input-border)";
                passwordInputWrapperRef.current.style.border="1px solid var(--err-input-border)";
              }
    
              //display the error
              passwordInputError.current.textContent=errMessage;
              passwordInputError.current.style.display="block";
    
  
              //update the status of the button and hide the loader
              setSigninButtonStatus(false)
              e.target.firstElementChild.style.display="none"
          }
        }

      }
    }

  };

  return (
      <main>
        <div className="language-toggle-button-wrapper">
          <div className="language-toggle-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="15"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.35 16.92 6ZM10 2.04C10.83 3.24 11.48 4.57 11.91 6H8.09C8.52 4.57 9.17 3.24 10 2.04ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12H2.26ZM3.08 14H6.03C6.35 15.25 6.81 16.45 7.41 17.56C5.57 16.93 4.04 15.66 3.08 14ZM6.03 6H3.08C4.04 4.34 5.57 3.07 7.41 2.44C6.81 3.55 6.35 4.75 6.03 6ZM10 17.96C9.17 16.76 8.52 15.43 8.09 14H11.91C11.48 15.43 10.83 16.76 10 17.96ZM12.34 12H7.66C7.57 11.34 7.5 10.68 7.5 10C7.5 9.32 7.57 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12H14.36Z"
                fill="#ABABAB"
              />
            </svg>
            <span className="ng-binding">English</span>
          </div>
        </div>

        <div className="signin-form">
          <a href="/">
            <h1 className="signin-top-header">Stack Financial</h1>
          </a>
          <h2>Login to Your Account</h2>
          <p>
            Manage your accounts and access insightful reports and technical
            analysis among many more features.
          </p>
          {/* email input wrapper*/}
          <div className="email-wrapper form-input-wrapper">
            <label htmlFor="email">Email</label>
            <div className="email-input-wrapper input-wrapper" ref={emailInputWrapper}>
              <input
                className="email-input input"
                type="email"
                value={email}
                onChange={handleEmailChange}
                onFocus={handleInputFocus}
                placeholder="example@email.com"
                id="email"
              />
            </div>
            <div className="email-input-error input-error" ref={emailInputError}>
              This email address is not recognised. Please try again.
            </div>
          </div>
          <div className="sub-form" ref={subForm}>
            {/* Password input*/}
            <PasswordWrapper 
              password={password}
              handlePasswordChange={handlePasswordChange}
              passwordInputWrapperRef={passwordInputWrapperRef}
            />
            <div className="password-input-error input-error" ref={passwordInputError}>
            </div>
            {/*remember-me check box*/}
            <div className="remember-me-forgot-password-wrapper">
                <div className="remember-me">
                    <input 
                        type="checkbox" 
                        id="RememberMeCheckBox" 
                        className="custom-checkbox" 
                        onChange={(e)=>handleCheckBoxChange(e)}
                    />
                    <label htmlFor="RememberMeCheckBox">Remember me</label>
                </div>
                <div className="forgot-password">
                    Forgot password?
                </div>
            </div>
          </div>
          <button
            type="button"
            onClick={(e)=>handleSignIn(e)}
            className="signin-button form-button"
          >
            <svg width="20" height="20" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 0 }} />
                    </linearGradient>
                </defs>
                <circle cx="25" cy="25" r="20" stroke="url(#gradientStroke)" strokeWidth="4" fill="none" strokeLinecap="round"/>
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    from="0 0 0"
                    to="360 0 0"
                    dur="1s"
                    repeatCount="indefinite"/>
            </svg>
            Continue
          </button>
          {/* comments comments comments*/}
          <div className="different-account-option">
            Sign in with a different account
          </div>
        </div>

        <div className="signin-create-account-note">
          <span>Don't have an account? </span>
          <span>
            <a href="/signup">Sign Up</a>
          </span>
        </div>
      </main>
  );
};

export default SignInPage;
