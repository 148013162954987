import React, { useState } from 'react';
import '../components_styles/signup.css'
import PasswordToggle from './passwordToggle';

const PasswordWrapper = ({password, handlePasswordChange, passwordInputWrapperRef}) => {
    //state variables


    //This function highlights the password input when it's been focused
    const handleInputFocus = (e) => {
        e.target.parentElement.style.border="1px solid var(--base-color)";
        e.target.onblur = (e) => {
            e.target.parentElement.style.border="1px solid #EBEBEB";
        }
    }

    /*
    //This function switches between a visible password and a non-visible password when the password hider/displayer is clicked
    const togglePasswordView=(e)=>{
        // Toggle back and forth
        //console.log(e.target.nextElementSibling)
        let hidePasswordBlob= hidePassword.current
        let showPasswordBlob= showPassword.current
        let parentInput = e.target.parentElement.parentElement.firstElementChild
        //console.log(parentInput)

        if (showPasswordBlob.style.display === "none") {
            hidePasswordBlob.style.display="none";
            showPasswordBlob.style.display="block";

            parentInput.setAttribute("type","text")                
        } else {
            showPasswordBlob.style.display="none";
            hidePasswordBlob.style.display="block";

            parentInput.setAttribute("type","password")                
        }

    }
    */

    return(
        <div className="password-wrapper form-input-wrapper">
            <label htmlFor="password">Password</label>
            <div className="password-input-wrapper input-wrapper" ref={passwordInputWrapperRef}>
                <input
                    className="password-input input"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    //onFocus={handleInputFocus}
                    id="password"
                />
                <PasswordToggle></PasswordToggle>
                {/*
                */}
            </div>
            <div className="password-input-error input-error">
            </div>
        </div>
    )
}

export default PasswordWrapper;
