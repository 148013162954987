import React from 'react';
//import './base.css';
import SignInPage from './components/signin';
import SignUpPage from './components/signup';
import Hash from './components/hash';
import Home from './components/home';
import Dashboard from './components/Dashboard';
import KycComponent from './components/Dashboard_Sub/kyc';
import SupportComponent from './components/Dashboard_Sub/Support';
import AboutComponent from './components/About';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';



function App() {

  return (
    <Router>
      <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/dashboard" element={<Hash/>} />
          <Route path="/path" element={<Hash/>} />
          <Route path="/referral" element={<SignUpPage/>} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/client-area" element={<Dashboard />} />
          <Route path="/know-your-customer" element={<Hash/>} />
          <Route path="/kyc" element={<KycComponent />} />
          <Route path="/support" element={<SupportComponent />} />
          <Route path="/about" element={<AboutComponent />} />
      </Routes>
    </Router>
  );
}


export default App;
