import React, { useRef, useEffect } from 'react';

const PasswordToggle = () => {

    //references
    const showPassword = useRef(null);
    const hidePassword = useRef(null);
    const passwordToggle = useRef(null);

    //useEffect method
    useEffect(()=>{
        showPassword.current.style.display = "none";
    })
    
    //This function switches between a visible password and a non-visible password when the password hider/displayer is clicked
    const togglePasswordView=(e)=>{
        // Toggle back and forth
        //console.log(e.target.nextElementSibling)
        let hidePasswordBlob= hidePassword.current
        let showPasswordBlob= showPassword.current
        let parentInput = e.target.parentElement.parentElement.firstElementChild
        //console.log(parentInput)

        if (showPasswordBlob.style.display === "none") {
            hidePasswordBlob.style.display="none";
            showPasswordBlob.style.display="block";

            parentInput.setAttribute("type","text")                
        } else {
            showPasswordBlob.style.display="none";
            hidePasswordBlob.style.display="block";

            parentInput.setAttribute("type","password")                
        }

        /*
        */
    }

    return(
        <div 
            className="password-status-toggle"
            ref={passwordToggle}
            onClick={togglePasswordView}
        >
            <span className="hide-password" ref={hidePassword}></span>
            <span className="show-password" ref={showPassword}></span>

        </div>
    )
}

export default PasswordToggle;
