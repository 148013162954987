import React, { useState, useRef, useEffect, useCallback} from 'react';
import axios from 'axios';
import '../components_styles/dashboard.css';
import Analysis from './Dashboard_Sub/Analysis'
import ReferralComponent from './Dashboard_Sub/Referral'
import {
    navigateTo,
    toggleErrorDisplay
} from '../util/generic'

const Dashboard = () => {
    const [genericButtonStatus, setGenericButtonStatus] = useState(false);

    const [accounts, setAccounts] = useState([]);
    const [activities, setActivities] = useState([])
    const [firstName, setFirstName]=useState('')
    const [lastName, setLastName]=useState('')

    const [, setFirstAccountName]=useState('')
    const [, setFirstAccountBalance]=useState('')

    const [totalBalance, setTotalBalance]=useState('')
    const [totalCredit, setTotalCredit]=useState('')
    const [totalEquity, setTotalEquity]=useState('')
    const [totalDeposits, setTotalDeposits]=useState('')
    const [totalWithdrawals, setTotalWithdrawals]=useState('')

    const [mobile, setMobile]=useState(true)
    const [userMenuState, setUserMenuState] = useState(false)
    const [activeMenuItem, setActiveMenuItem] = useState("goto-landing-board")
    const [activeBoard, setActiveBoard] = useState("landing-board")

    const [kyced,setKyced] = useState(true)

    const [indicator,setIndicator] = useState('')
    
    //deposit section
    const [depositAmount, setDepositAmount] = useState('')
    const [, setDepositAccount]=useState('')
    const [depositAccountStatus, setDepositAccountStatus]=useState(false)
    const [activeWrapperId, setActiveWrapperId] = useState('')
    const [depositAmountStatus, setDepositAmountStatus] = useState(false)
    
    //withdrawal section
    const [, setWithdrawalAccount]=useState('')
    const [withdrawalAmount, setWithdrawalAmount] = useState('')
    const [availableWithdrawal, setAvailableWithdrawal] = useState('')
    const [withdrawalAmountStatus, setWithdrawalAmountStatus] = useState(false)
    const [withdrawalAccountStatus, setWithdrawalAccountStatus]=useState(false)

    //transfer section
    const [transferToAccount, setTransferToAccount]=useState('')
    const [transferToAccountStatus, setTransferToAccountStatus]=useState(false)
    const [transferFromAccount, setTransferFromAccount]=useState('')
    const [transferFromAccountStatus, setTransferFromAccountStatus]=useState(false)
    const [transferAmount, setTransferAmount] = useState('')
    const [availableTransfer, setAvailableTransfer] = useState('')
    const [transferAmountStatus, setTransferAmountStatus] = useState(false)
    
    //analysis section
    const[newsData, setNewsData] = useState(null)

    //referral section
    const[refCode, setRefCode] = useState('')
    const[refData, setRefData] = useState([])
    const[totalEarnings, setTotalEarnings] = useState(0)
    const[totalInvited, setTotalInvited] = useState(0)

    //documents section
    const [documents, setDocuments] = useState(null)

    //references
    const menu = useRef(null);
    const menuBtn = useRef(null);
    const menuCloseBtn = useRef(null);
    const emptyMenuBtn = useRef(null);
    const userMenuBtn = useRef(null);
    const dashboardContent = useRef(null);
    const userMenu = useRef(null);
    const dropdownContent = useRef(null);
    const dropBtnTitle = useRef(null);

    const signinUrl="/signin"
    const dashboardUrl="/dashboard"

    // Calculate and set total deposits whenever accounts data changes
    useEffect(() => {
        const totalDeposits = accounts.reduce((acc, curr) => acc + parseFloat(curr.total_deposits), 0);
        const totalBalance = accounts.reduce((acc, curr) => acc + parseFloat(curr.total_balance), 0);
        const totalCredit = accounts.reduce((acc, curr) => acc + parseFloat(curr.total_credit), 0);
        const totalEquity = accounts.reduce((acc, curr) => acc + parseFloat(curr.total_equity_worth), 0);
        const totalWithdrawals = accounts.reduce((acc, curr) => acc + parseFloat(curr.total_withdrawals), 0);
    
        setTotalDeposits(totalDeposits.toString());
        setTotalBalance(totalBalance.toString());
        setTotalCredit(totalCredit.toString());
        setTotalEquity(totalEquity.toString());
        setTotalWithdrawals(totalWithdrawals.toString());
    }, [accounts]);

    useEffect(()=>{
        //make the html element the browser's height
        document.documentElement.style.minHeight= window.innerHeight+"px";
        document.documentElement.style.backgroundColor= "#F5F6F7";

        if(window.matchMedia('(min-width: 912px)').matches){
            setMobile(false)
        }

        if (localStorage.getItem('logged_out')==='true'){
            localStorage.removeItem('logged_out');
            // Reload the page
            window.location.reload();
        }
    },[])

    //called when the referral section filter is used
    const filterData = useCallback((filterType) => {
        const currentDate = new Date();
        let filteredData = [];
    
        if (refData.length === 0) {
            setTotalEarnings(0);
            setTotalInvited(0);
            return;
        }
    
        switch (filterType) {
            case 'alltime':
            filteredData = refData;
            break;
            case 'oneweek':
            filteredData = refData.filter(item => {
                const itemDate = new Date(item.timestamp);
                const diffTime = Math.abs(currentDate - itemDate);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                return diffDays <= 7;
            });
            break;
            case 'onemonth':
            filteredData = refData.filter(item => {
                const itemDate = new Date(item.timestamp);
                const diffTime = Math.abs(currentDate - itemDate);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                return diffDays <= 30;
            });
            break;
            default:
            filteredData = refData;
            break;
        }
        
        const totalEarningsValue = filteredData.reduce((acc, item) => acc + item.reward_issued, 0);
        const totalInvitedValue = filteredData.length;
    
        setTotalEarnings(totalEarningsValue);
        setTotalInvited(totalInvitedValue);
    },[refData]);

    //Runs when ever the refData changes
    useEffect(() => {
        filterData('alltime');
    }, [refData,filterData]);

    //Fetch inventory
    useEffect(() => {
        const url = window.location.href.includes("localhost")
            ? "http://localhost:8000/coinstackcapital/client-detail/"
            : "https://noapiere.pythonanywhere.com/coinstackcapital/client-detail/";
    
        // Function to fetch data with access token
        const fetchDataWithAccessToken = () => {
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                }
            };
    
            return axios.get(url, config);
        };
    
        // Function to refresh access token with refresh token
        const refreshAccessToken = () => {
            const url = window.location.href.includes("localhost")
            ? "http://localhost:8000/coinstackcapital/refresh-token/"
            : "https://noapiere.pythonanywhere.com/coinstackcapital/refresh-token/";   

            const refreshToken = localStorage.getItem("refresh_token");
            const refreshUrl = url
    
            return axios.post(refreshUrl, { refresh: refreshToken });
        };
    
        fetchDataWithAccessToken()
            .then(response => {
                let result = response.data.message;
                setAccounts(result.accounts);
                setFirstAccountName(result.accounts[0].account_name)
                setFirstAccountBalance(result.accounts[0].total_balance)
                setActivities(result.logs);
                setFirstName(result.full_name.first_name);
                setLastName(result.full_name.last_name);
                //referral content data
                setRefCode(result.ref_code)
                setRefData(result.ref_data)
                //kyc data
                result.kyc_initiated_or_approved?setKyced(true):setKyced(false)
                //kyc documents
                setDocuments(result.documents)
            })
            .catch(error => {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    // Unauthorized error, try refreshing access token
                    refreshAccessToken()
                        .then(response => {
                            // Store new access token in localStorage
                            localStorage.setItem("access_token", response.data.access_token);
                            // Retry fetching data with the new access token
                            fetchDataWithAccessToken()
                                .then(response => {
                                    let result = response.data.message;
                                    setAccounts(result.accounts);
                                    setFirstAccountName(result.accounts[0].account_name)
                                    setFirstAccountBalance(result.accounts[0].total_balance)
                                    setActivities(result.logs);
                                    setFirstName(result.full_name.first_name);
                                    setLastName(result.full_name.last_name);
                                    //referral content data
                                    setRefCode(result.ref_code)
                                    setRefData(result.ref_data)
                                    //kyc data
                                    result.kyc_initiated_or_approved?setKyced(true):setKyced(false)
                                    //kyc documents
                                    setDocuments(result.documents)
                                })
                                .catch(innerError => {//invalid session
                                    console.error("Error fetching data after token refresh:", innerError);
                                    // Handle error fetching data after token refresh   
                                    navigateTo(dashboardUrl)                              
                                });
                        })
                        .catch(refreshError => {//invalid refresh token
                            console.error("Error refreshing access token:", refreshError);
                            // Handle error refreshing access token
                            navigateTo(dashboardUrl)
                        });
                } else {//invalid refresh token
                    console.error("Error fetching data:", error);
                    // Handle other errors
                    navigateTo(dashboardUrl) 
                }
            });
    }, []);

    const toggleMenuOpen = (e) => {
        //menuBtn.classList.toggle('open');
        e.target.style.display="none"
        emptyMenuBtn.current.style.display="block" 
        userMenuBtn.current.style.display="none"
        menu.current.style.left="0px";
        menuCloseBtn.current.style.display="block"

        dashboardContent.current.style.display= "none";
    }

    const toggleMenuClose = (e) => {
        dashboardContent.current.style.display= "block";

        //menuBtn.classList.toggle('open');
        e.target.style.display="none"
        emptyMenuBtn.current.style.display="none"
        menu.current.style.left="-100%";
        userMenuBtn.current.style.display="block"
        menuBtn.current.style.display="block"
    }

    const closeUserMenu = () => {
        let menuUser=userMenu.current

        menuUser.style.right = "-100%"
        menuUser.style.display = "none"
        document.querySelector('.user-menu-btn').style.filter="none"

        setUserMenuState(false)
    }
    
    const toggleUserMenu = () => {
        let menuUser=userMenu.current
        if(userMenuState){
            menuUser.style.right = "-100%"
            menuUser.style.display = "none"
            document.querySelector('.user-menu-btn').style.filter="none"

            setUserMenuState(false)
        }
        else{
            let menuUser=userMenu.current

            menuUser.style.right = "5px"
            menuUser.style.display = "block"
            document.querySelector('.user-menu-btn').style.filter="invert(48%) sepia(61%) saturate(961%) hue-rotate(137deg) brightness(91%) contrast(100%)"
    
            setUserMenuState(true)
        }
    }

    const handleMenuClick = (e) => {
        //This reference the target element
        let target=e.target
        if(!e.target["id"]){
            target=e.target.parentElement
        }
        let activeItem=document.getElementById(`${activeMenuItem}`)

        //remove the active effect
        activeItem.style.borderLeft =  "none";
        // Get the SVG element
        activeItem.firstElementChild.style.filter="none"

        // Get the SVG element
        target.firstElementChild.style.filter="invert(48%) sepia(61%) saturate(961%) hue-rotate(137deg) brightness(91%) contrast(100%)";
        target.style.borderLeft =  "5px solid var(--base-color)";
        
        //make the active element known
        setActiveMenuItem(`${target["id"]}`)

        //dashboard's active pane
        let active = document.getElementById(`${activeBoard}`)
        active.style.display="none";

        //Give the dashboard a top an indicator
        setIndicator(target.getAttribute('indicator'))


        switch(target["id"]){
            case `goto-${target["id"].substring(5)}`:
                let postActive=document.getElementById(`${target["id"].substring(5)}`)
                postActive.style.display="block";
                setActiveBoard(`${postActive["id"]}`)

                //close the menu on mobile screens
                if(mobile){
                    dashboardContent.current.style.display= "block";
                    menuCloseBtn.current.style.display="none"
                    emptyMenuBtn.current.style.display="none"
                    menu.current.style.left="-100%";
                    userMenuBtn.current.style.display="block"
                    menuBtn.current.style.display="block"
                }
                //For fetching of analysis
                if (target["id"]==="goto-documents-content"){
                    closeUserMenu()
                }

                //For closing the user menu when the documents is open
                if (target["id"]==="goto-analysis-content"){
                    fetchAnalytics()
                }

                //For fetching the referral-content
                if (target["id"]==="goto-referral-content"){
                    document.querySelector(".referral-notice").style.display="none"
                }else{
                    document.querySelector(".referral-notice").style.display="flex"
                }
                break;
            default:
                break;
        }

    }

    const toggleLoader = (selector,display,status) =>{
        document.querySelector(selector).firstElementChild.style.display=display;
        if (display==="none"){
            document.querySelector(selector).style.filter="grayscale(0)"
        }else{
            document.querySelector(selector).style.filter="grayscale(0.4)"
        }
        setGenericButtonStatus(status)
    }

    const toggleDisplay = (x,y,z) =>{
        return toggleErrorDisplay(x,y,z)
    }

    //landing content
    const toggleActivityHeight = (e) => {
        if (e.currentTarget.textContent==='View More'){
            document.querySelector('.recent-activity').style.minHeight='79px';
            e.currentTarget.textContent='View Less'
        }else{
            document.querySelector('.recent-activity').style.maxHeight='158px';
            e.currentTarget.textContent='View More'
        }   
    }

    //Deposit section
    const handleDepositAmountChange = (e) => {
        setDepositAmount(e.target.value);

    };
    
    const handleDepositAmountInput = (e) => {

        HideInputError(".deposit-input-amount-wrapper",".deposit-input-amount-error")
    
        let amount=Number(e.target.value)
        if(amount>0 && (/^\d+(?:\.\d{1,2})?$/.test(amount))){
            e.target.style.border="none"
            document.querySelector(".deposit-input-amount-wrapper").style.border="1px solid var(--valid-input-border)"
            setDepositAmountStatus(true)
        }
        else{
            displayInputError(".deposit-input-amount-wrapper",".deposit-input-amount-error","Please input a correct amount.")
        }

    }

    const displayInputError = (wrapperSelector,errorSelector,errMessage) => {
        document.querySelector(`${wrapperSelector}`).style.border="1px solid var(--err-input-border)"
        let errInput=document.querySelector(`${errorSelector}`)
        errInput.textContent=errMessage
        errInput.style.display="block"
    }

    const HideInputError = (wrapperSelector,errorSelector) => {
        document.querySelector(`${wrapperSelector}`).style.border="1px solid var(--input-wrapper-border)"
        document.querySelector(`${errorSelector}`).style.display="none"
    }

    const handlePreBtn = (e) => {
        if(!depositAccountStatus){
            displayInputError(".deposit-input-account-wrapper",".deposit-input-account-error","Please select an account.")
        }
        else if(!depositAmountStatus){
            displayInputError(".deposit-input-amount-wrapper",".deposit-input-amount-error","Please input a correct amount.")
        }else{
            HideInputError(".deposit-input-account-wrapper",".deposit-input-account-error")
            HideInputError(".deposit-input-amount-wrapper",".deposit-input-amount-error")

            let parentElem = e.target.parentElement
            let parentElemII = e.target.parentElement.parentElement
            let nextElem = parentElem.nextElementSibling
            parentElemII.style.width = parentElemII.offsetWidth+"px"
            parentElem.style.position="absolute";
            parentElem.style.left="-100%"
            
            nextElem.style.left="0"
            nextElem.style.position="relative"
        }
    }

    const handlePsdwBackBtn = (e) => {
        let parentElem = e.target.parentElement
        let prevElem = parentElem.previousElementSibling

        parentElem.style.position="absolute"
        parentElem.style.left="100%"
        prevElem.style.left="0%"
        prevElem.style.position="relative";
    }

    const handleDropdownBtn = (e) =>{
        e.target.nextElementSibling.classList.toggle("show");
    }
    
    const handleDropdownEntry = (e) =>{
        dropdownContent.current.classList.remove("show");
        dropBtnTitle.current.textContent=e.target.textContent

        if(activeWrapperId !== ''){
            document.getElementById(`${activeWrapperId}-wrapper`).style.display="none"
        }

        let elem=document.getElementById(`${e.target["id"]}-wrapper`);
        elem.style.display="block"
        setActiveWrapperId(e.target["id"])
    }
    
    const handleCopyBtn = (e) => {

        navigator.clipboard.writeText((e.target.parentElement.firstElementChild.textContent).trim());
        e.target.style.backgroundColor="#000"
        e.target.style.color="#FFF"
        e.target.textContent="copied"
        setTimeout(()=>{
            e.target.style.backgroundColor="#FFF"
            e.target.style.color="#000"
            e.target.textContent="copy"
        },50000)
        e.target.parentElement.parentElement.parentElement.nextElementSibling.style.display="block"
    }

    const handlePaidBtn = (e) => {
        e.target.textContent="processing..."
        e.target.nextElementSibling.textContent="Your account shall be credited once your transaction is confirmed."
        e.target.nextElementSibling.style.color="#F79D38"
    }

    const handleLogoutBtn = () => {
        
        localStorage.setItem('logged_out','true')
        const logOutHead = () => {  
            const url = window.location.href.includes("localhost")
            ? "http://localhost:8000/coinstackcapital/logout/"
            : "https://noapiere.pythonanywhere.com/coinstackcapital/logout/";

            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                }
            };
    
            return axios.get(url, config);
        };
    
        logOutHead()
            .then(response => {
                navigateTo(signinUrl)
            })
            .catch(error => {
                navigateTo(signinUrl)
                //if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                //console.log(error.response.message.data)
            });
    }

    const updateToggleTitle = (e,valueSelected) => {
        let lnk=e.target.getAttribute("lnk")

        //element that displays the selected content
        let selectedDisplay=document.querySelector(`span[lnk=${lnk}]`)
        selectedDisplay.textContent=e.target.textContent

        //hide the dropdown
        e.target.parentElement.classList.toggle("show")
        //nextObject(e) ; call the next funtion passing the event object through

        switch(lnk){
            case "account-choices":
                //hide error if any
                HideInputError(".deposit-input-account-wrapper",".deposit-input-account-error")
                //style the border and text
                //selectedDisplay.style.color="var(--valid-input-border)"
                selectedDisplay.parentElement.style.border="1px solid var(--valid-input-border)"

                setDepositAccountStatus(true)
                //setting the account value selected
                setDepositAccount(valueSelected)
                break;
                
            case "withdraw-account-choice":
                    //hide error if any
                HideInputError(".withdrawal-input-account-wrapper",".withdrawal-input-account-error")
                //style the border and text
                //selectedDisplay.style.color="var(--valid-input-border)"
                selectedDisplay.parentElement.style.border="1px solid var(--valid-input-border)"
                
                setWithdrawalAccountStatus(true)
                //setting the account value selected
                setWithdrawalAccount(valueSelected)
                break;
            case "transfer-from-account-choice":
                    //hide error if any
                HideInputError(".transfer-from-input-account-wrapper",".transfer-from-input-account-error")
                //style the border and text
                //selectedDisplay.style.color="var(--valid-input-border)"
                selectedDisplay.parentElement.style.border="1px solid var(--valid-input-border)"
                
                setTransferFromAccountStatus(true)
                //setting the account value selected
                setTransferFromAccount(valueSelected)
                break;
            case "transfer-to-account-choice":
                    //hide error if any
                HideInputError(".transfer-to-input-account-wrapper",".transfer-to-input-account-error")
                //style the border and text
                //selectedDisplay.style.color="var(--valid-input-border)"
                selectedDisplay.parentElement.style.border="1px solid var(--valid-input-border)"
                
                setTransferToAccountStatus(true)
                //setting the account value selected
                setTransferToAccount(valueSelected)
                break;
            default:
                break;
        }
    }

    const randomTimeout = (func) => {
        const randomTime = Math.floor(Math.random() * (10 - 8 + 1) + 8);
        setTimeout(func, randomTime * 1000);
    }

    //Withdrawal section
    const handleWithdrawalAmountChange = (e) => {
        setWithdrawalAmount(e.target.value);

    };

    const handleWithdrawalAmountInput = (e) => {

        HideInputError(".withdrawal-input-amount-wrapper",".withdrawal-input-amount-error")
    
        let amount=Number(e.target.value)
        if(amount>0 && (/^\d+(?:\.\d{1,2})?$/.test(amount))){
            e.target.style.border="none"
            document.querySelector(".withdrawal-input-amount-wrapper").style.border="1px solid var(--valid-input-border)"
            setWithdrawalAmountStatus(true)
        }
        else{
            displayInputError(".withdrawal-input-amount-wrapper",".withdrawal-input-amount-error","Please input a correct amount.")
        }

    }

    const handleWithdrawalPreBtn = (e) => {
        if(!withdrawalAccountStatus){
            displayInputError(".withdrawal-input-account-wrapper",".withdrawal-input-account-error","Please select an account.")
        }
        else if(!withdrawalAmountStatus){

            displayInputError(".withdrawal-input-amount-wrapper",".withdrawal-input-amount-error","Please input a correct amount.")

        }else if( Number(withdrawalAmount) <= 0 || Number(withdrawalAmount) > Number(availableWithdrawal)){

            displayInputError(".withdrawal-input-amount-wrapper",".withdrawal-input-amount-error","Insufficient amount for withdrawal.")
        }
        else{
            HideInputError(".withdrawal-input-account-wrapper",".withdrawal-input-account-error")
            HideInputError(".withdrawal-input-amount-wrapper",".withdrawal-input-amount-error")

            e.target.firstElementChild.style.display="block"
            //set the generic button status to true
            setGenericButtonStatus(true)

            //call the initiate withdrawal function if the generic button is inactive
            if(!genericButtonStatus){
                initiateWithdrawal()
            }
        }
    }

    const initiateWithdrawal = () => {
        //remove all possible errors
        toggleDisplay(
            ['.withdrawal-input-amount-message','.input-message-patch','.withdrawal-input-amount-error'],
            ['','',''],
            ['none','none','none']
        )
        const uploadAction = () => {
            const url = window.location.href.includes("localhost")
            ? "http://localhost:8000/coinstackcapital/withdrawal-initiation/"
            : "https://noapiere.pythonanywhere.com/coinstackcapital/withdrawal-initiation/";   

            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                }
            };

            const postData={
                'amount':withdrawalAmount,
            }

    
            return axios.post(url,postData,config);
        };
    
        uploadAction()
            .then((response) => {
                //Hide the loader
                toggleLoader(
                    '.pre-withdrawal-button',
                    'none',
                    false
                )

                //display an initiation message
                toggleDisplay(
                    ['.withdrawal-input-amount-message','.input-message-patch'],
                    [
                        `${response.data.message}`,
                        'Thanks for your patience'
                    ],
                    ['block','block']
                ) 
            })
            .catch(error => {
                console.log(error)
                //remove the loader and set the button status to false; inactive
                toggleLoader(
                    '.pre-withdrawal-button',
                    'none',
                    false
                )

                if (error.response && (error.response.status === 401)){
                    //Means the user's session is expired. Navigate to the hash page
                    /* 
                    Inform the user that there session has expired 
                    and they'll be redirected in a few seconds
                    */
                    toggleErrorDisplay(
                        ['.withdrawal-input-amount-error'],
                        ['You have an expired session and will be redirected in a few seconds.'],
                        ['block']
                    )
                    setTimeout(()=>{
                        navigateTo('/dashboard')
                    }, 5000)
                }else{
                    //Display the error
                    toggleErrorDisplay(
                        ['.withdrawal-input-amount-error'],
                        ['An error occured. Please check your internet connection and try again.'],
                        ['block']
                    )
                }
            });
    }

    //Transfer section
    const handleTransferAmountChange = (e) => {
        setTransferAmount(e.target.value);

    };
    const handleTransferAmountInput = (e) => {

        HideInputError(".transfer-input-amount-wrapper",".transfer-input-amount-error")
    
        let amount=Number(e.target.value)
        if(amount>0 && (/^\d+(?:\.\d{1,2})?$/.test(amount))){
            e.target.style.border="none"
            document.querySelector(".transfer-input-amount-wrapper").style.border="1px solid var(--valid-input-border)"
            setTransferAmountStatus(true)
        }
        else{
            displayInputError(".transfer-input-amount-wrapper",".transfer-input-amount-error","Please input a correct amount.")
        }

    }
    const handleTransferPreBtn = (e) => {
        if(!transferFromAccountStatus){
            displayInputError(".transfer-from-input-account-wrapper",".transfer-from-input-account-error","Please select an account.")
            if (!transferToAccountStatus){
                displayInputError(".transfer-to-input-account-wrapper",".transfer-to-input-account-error","Please select an account.")
            }
        }
        else if(!transferToAccountStatus){

            displayInputError(".transfer-to-input-account-wrapper",".transfer-to-input-account-error","Please select an account.")
            
        }else if(transferFromAccount === transferToAccount){
            
            displayInputError(".transfer-to-input-account-wrapper",".transfer-to-input-account-error","Recipient account can' t be same as sender account.")
        }else if(!transferAmountStatus){

            displayInputError(".transfer-input-amount-wrapper",".transfer-input-amount-error","Please input a correct amount.")

        }else if( Number(transferAmount) <= 0 || Number(transferAmount) > Number(availableTransfer)){

            displayInputError(".transfer-input-amount-wrapper",".transfer-input-amount-error","Insufficient amount to transfer.")
        }
        else{
            HideInputError(".transfer-from-input-account-wrapper",".transfer-from-input-account-error")
            HideInputError(".transfer-to-input-account-wrapper",".transfer-to-input-account-error")
            HideInputError(".transfer-input-amount-wrapper",".transfer-input-amount-error")

            e.target.firstElementChild.style.display="block"
            randomTimeout(()=>{
                e.target.firstElementChild.style.display="none"
                displayInputError(".transfer-input-amount-wrapper",".transfer-input-amount-error","An error occured. Try again later or contact support.")
            })
        }
    }

    //Analytics
    const fetchAnalytics = () => {
        const getAnalytics = () => {
            const url = window.location.href.includes("localhost")
            ? "http://localhost:8000/coinstackcapital/fetch-analysis/"
            : "https://noapiere.pythonanywhere.com/coinstackcapital/fetch-analysis/";   

            return axios.get(url);
        };

        getAnalytics()
        .then((response) => {
            let data = response.data.message.results;
            const categorizedData = {
                "Market Updates and Analysis": [],
                "Economic Indicators and Reports": [],
                "Corporate Earnings and Reports": [],
                "Regulatory and Policy Developments": [],
                "Personal Finance and Investment Advice": []
            };
        
            data.forEach(entry => {
                // Randomly select a category
                const categories = Object.keys(categorizedData);
                const randomCategory = categories[Math.floor(Math.random() * categories.length)];
        
                // Push the entry into the randomly selected category
                categorizedData[randomCategory].push(entry);
            });
        
            setNewsData(categorizedData);
            //
        })
        .catch((error) =>{
            console.log(error)
        })
    }



  return (
    <div className="client-area">
        <div className="top-bar-wrapper">
            {/*Marquee slideshow*/}
            <div id="coinmarketcap-widget-marquee" coins="1,1027,825" currency="USD" theme="light" transparent="false" show-symbol-logo="true"></div>
            {/*top bar element*/    }
            <div className="top-bar">
                <div 
                    className="menu-btn"
                    onClick={(e)=>toggleMenuOpen(e)}
                    ref={menuBtn}
                >
                </div>
                <div className="landscape-uwm user-welcome-message">
                    Hello 
                    <span>
                        {` ${firstName} ${lastName}`} 😃
                    </span>
                </div>
                <div className="empty-menu-btn" ref={emptyMenuBtn}></div>
                {/*title*/}
                <a href="/">
                    <h1 className="top-header">
                            Stack Financial
                    </h1>
                </a>
                {/*user menu button*/}
                <div 
                    className="user-menu-btn" 
                    ref={userMenuBtn}
                    onClick={toggleUserMenu}
                >
                    {/*User Full Name*/}
                    <div className="name-toggle">
                        {(firstName.length + lastName.length + 1) <= 14 ? 
                            `${firstName} ${lastName}` :
                            firstName
                        }
                    </div>
                </div>
                {/*Menu close button*/}
                <div 
                    className="menu-close-btn" 
                    ref={menuCloseBtn}
                    onClick={(e)=>toggleMenuClose(e)}
                    >
                </div>
                {/*locator*/}
                <div className="dashboard-pane-indicator">
                    <span>&gt; Dashboard</span>
                    {
                        indicator? 
                        (
                            <span> &gt; <span>{indicator}</span></span>
                        ):''
                    }
                </div>

            </div>
        </div>
        <main>            
            {/*------------SMiley Welcome meSSage-------------*/}
            <div className="mobile-uwm user-welcome-message">
                Hello 
                <span>
                    {` ${firstName} ${lastName}`} 😃
                </span>
            </div>

            {/*This section displays if the user is not kyc verified or has none initiated*/}
            { 
                !kyced?(
                    <div className="kyc-notice" style={{marginBottom : "-30px"}}>{/*<!--kn=>kyc-notice-->*/}
                        <div className="kn-text">
                            <div></div>
                            <div>
                                You can deposit up to USD 2,000. Complete full verification to make deposits without limitations.
                            </div>
                        </div>
                        <div className="kn-link">
                            <a href="/know-your-customer">
                                <button>
                                    Complete verification
                                </button>
                            </a>
                        </div>
                    </div>
                ):""

            }

            {/*------------right user menu-------------*/}
            <div 
                className="user-menu"
                ref={userMenu}
            >
                <h4 className="fullname">
                    {`${firstName} ${lastName}`}
                </h4>
                <ul>
                    <li 
                        id="goto-documents-content" 
                        onClick={(e)=>handleMenuClick(e)}
                        indicator="documents"
                    >
                        <div></div>
                        My Documents
                    </li>
                    {/*
                    <li>My Settings</li>
                    */}
                    <li><a href="/support">Support</a></li>
                </ul>
                <div 
                    className="user-menu-footer"
                >
                    <div onClick={handleLogoutBtn}>Logout</div>
                </div>
            </div>
            {/*------------Menu-------------*/}
            <nav className="menu" ref={menu}>
                <a href="/">
                    <h1 className="top-header">
                        Stack Financial
                    </h1>
                </a>
                <ul>
                    <li 
                        id="goto-landing-board" 
                        onClick={(e)=>handleMenuClick(e)}
                    >
                        <div className="list-icon1 list-icon"></div>
                        <span className="list-tag">Dashboard</span>
                    </li>
                    <li
                        id="goto-deposit-content"
                        onClick={(e)=>handleMenuClick(e)}
                        indicator="deposit"
                    >
                        <div className="list-icon2 list-icon"></div>
                        <span>Deposits</span>
                    </li>
                    <li
                        id="goto-withdrawal-content"
                        onClick={(e)=>handleMenuClick(e)}
                        indicator="withdrawal"
                    >
                        <div className="list-icon3 list-icon"></div>
                        <span>Withdrawals</span>
                    </li>
                    <li 
                        className="transfer"
                        id="goto-transfer-content"
                        onClick={(e)=>handleMenuClick(e)}
                        indicator="transfer"
                    >
                        <div className="list-icon4 list-icon"></div>
                        <span>Transfers</span>
                    </li>
                    <li
                        id="goto-report-content"
                        onClick={(e)=>handleMenuClick(e)}
                        indicator="report"
                    >
                        <div className="list-icon5 list-icon"></div>
                        <span>Reports</span>
                    </li>
                    <li
                        id="goto-analysis-content"
                        onClick={(e)=>handleMenuClick(e)}
                        indicator="analysis"
                    >
                        <div className="list-icon6 list-icon"></div>
                        <span>Analysis</span>
                    </li>
                    <li
                        id="goto-trading-platforms-content"
                        onClick={(e)=>handleMenuClick(e)}
                        indicator="platforms"
                    >
                        <div className="list-icon7 list-icon"></div>
                        <span>Platforms</span>
                    </li>
                    <li
                        id="goto-referral-content"
                        onClick={(e)=>handleMenuClick(e)}
                        indicator="referral"
                    >
                        <div className="list-icon8 list-icon"></div>
                        <span>Refer a Friend</span>
                    </li>
                    <li>
                        Legal Terms and Policies
                    </li>
                </ul>
            </nav>    
            {/*------------Dashboard content-------------*/}
            <div className="dashboard-content" ref={dashboardContent}>
                {/*<!--referral banner-->*/}
                <div className="referral-notice" style={!mobile && kyced ? { marginTop: "0px" } : {marginTop: "50px"}}>
                    <div className="referral-notice-caption">
                        <div className="banner-head">
                            Get up to USD 100 for every friend you refer
                        </div>
                        <div className="banner-note">
                            Invite a Like-Minded trader to create a live account and earn up to USD 100 per friend
                        </div>
                    </div>
                    <div className="banner-cta">Refer a friend</div>
                </div>
            

                {/*<!--dashboard landing content-->*/}
                <div 
                    className="main-dashboard"
                    id="landing-board"
                >
                    {/*Account summary*/}
                    <div className="account-summary-header">
                        <h3>Account Summary</h3>
                    </div>
                    <section className="account-summary tab-body">
                        <div className="account-summary-entry">
                            <div className="account-summary-note">
                                <h4>Total Balance</h4>
                                <div>{totalBalance ? parseFloat(totalBalance).toFixed(2) : "0.00"} USD</div>
                            </div>
                            <div className="account-summary-icon token-icon"></div>
                        </div>
                        <div className="account-summary-entry">
                            <div className="account-summary-note">
                                <h4>Total Credit</h4>
                                <div>{totalCredit ? parseFloat(totalCredit).toFixed(2) : "0.00"} USD</div>
                            </div>
                            <div className="account-summary-icon token-icon"></div>
                        </div>
                        <div className="account-summary-entry">
                            <div className="account-summary-note">
                                <h4>Total Equity</h4>
                                <div>{totalEquity ? parseFloat(totalEquity).toFixed(2) : "0.00"} USD</div>
                            </div>
                            <div className="account-summary-icon token-icon"></div>
                        </div>
                        <div className="account-summary-entry">
                            <div className="account-summary-note">
                                <h4>Total Deposits</h4>
                                <div>{totalDeposits ? parseFloat(totalDeposits).toFixed(2) : "0.00"} USD</div>
                            </div>
                            <div className="account-summary-icon deposit-icon"></div>
                        </div>
                        <div className="account-summary-entry">
                            <div className="account-summary-note">
                                <h4>Total Withdrawals</h4>
                                <div>{totalWithdrawals ? parseFloat(totalWithdrawals).toFixed(2) : "0.00"} USD</div>
                            </div>
                            <div className="account-summary-icon colored-withdrawal-icon"></div>
                        </div>
                    </section>

                    {/*<!--Recent Activity-->*/}
                    <div className="recent-activity-header tab-head">
                        <h3>Recent Activity</h3>
                        <div className="tab-head-action" onClick={(e)=>toggleActivityHeight(e)}>View More</div>
                    </div>
                    <section className="recent-activity tab-body">
                        {activities.map((activity, index) => (
                            <div key={index} className="recent-activity-entry">
                                <div className="recent-activity-datetime">
                                    {/* Splitting date_time string using space as the separator */}
                                    {activity.date_time.split(' ').map((dateTimePart, idx) => (
                                        <span key={idx} className={idx === 0 ? 'date' : 'time'}>{idx === 0 ? dateTimePart : ` ${dateTimePart}`}</span>
                                    ))}
                                </div>
                                <div className="recent-activity-note">{activity.label}</div>
                                {/* Using status.toLowerCase() to convert status to lowercase */}
                                <div className={`recent-activity-status ${activity.status.toLowerCase()}`}>{activity.status}</div>
                            </div>
                        ))}
                    </section>

                    {/*<!--Wallet Account-->*/}
                    <div className="wallet-account-header tab-head">
                        <h3>Wallet Accounts</h3>
                        <div className="tab-head-action">+ Create Account</div>
                    </div>
                    <section className="wallet-account tab-body">
                        {accounts.map((account, index) => (
                            <div key={index} className="wallet-account-entry">
                                <div className="account-name">
                                    <span className="usd-tag">USD</span>
                                    <span className="account-name-title">{account.account_name}</span>
                                </div>
                                <div className="balance">
                                    <div className="balance-tag">Balance</div>
                                    <div className="balance-amount">{account.total_balance ? parseFloat(account.total_balance).toFixed(2) : "0.00"}</div>
                                </div>
                                <div className="account-action">
                                    <div className="deposit">
                                        <div className="small-deposit-icon small-icon"></div>
                                        <div className="deposit-note">Deposit</div>
                                    </div>
                                    <div className="transfer">
                                        <div className="small-transfer-icon small-icon"></div>
                                        <div className="transfer-note">Transfer</div>
                                    </div>
                                    <div className="withdraw">
                                        <div className="small-withdraw-icon small-icon"></div>
                                        <div className="widthdraw-note">Withdraw</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </section> 
                </div>


                {/*<!--Deposit content-->*/}
                <div 
                    className="deposit-content dashboard-sect"
                    id="deposit-content"
                >
                    {/* deposit header */}
                    <div>
                        <h3>Fund Your Account Easily and Securely</h3>
                    </div>
                    <section className="tab-body deposit-bonus-section dash-sect-header">
                        <div className="deposit-bonus-note dash-header-note">
                            Deposit $100 or more to receive a complimentary 30% bonus credit
                        </div>
                    </section>

                    <section className="tab-body">
                        <div className="deposit-form-wrapper dashboard-sect-form-wrapper">
                            <div className="deposit-form dashboard-sect-form">
                                {/*deposit account selection*/}
                                <h4>
                                    <label>
                                        Select an Account
                                    </label>
                                </h4>
                                <div className="form-input-wrapper">
                                    <div className="custom-dropdown">
                                        <button 
                                            className="dropdown-toggle deposit-input-account-wrapper" 
                                            onClick={(e)=>{
                                                document.querySelector(".dropdown-entries").classList.toggle("show")
                                            }}
                                        >
                                            <span className="dropdown-toggle-title" lnk="account-choices">
                                                Select a payment account
                                            </span>
                                            <span className="dropdown-toggle-icon"></span>
                                        </button>
                                        <div className="dropdown-entries">
                                        {accounts.map((account, index) => (
                                            <li key={index} className="dropdown-entre" lnk="account-choices" onClick={(e)=>updateToggleTitle(e,account.account_name)}>{`${account.account_name} (USD ${account.total_balance ? parseFloat(account.total_balance).toFixed(2) : "0.00"})`}</li>
                                        ))}
                                        </div>
                                        <div className="deposit-input-account-error input-error dfie">{/*Dashboard form input error; dfie*/}
                                        </div>
                                    </div>
                                    {/*${firstAccountName} (USD ${firstAccountBalance ? parseFloat(firstAccountBalance).toFixed(2) : "0.00"})*/}
                                    {/*"account_name Wallet (USD total_balance)"*/}
                                </div>
                                {/*deposit account selection*/}
                                <h4>
                                    <label>
                                        Deposit Amount
                                    </label>
                                </h4>
                                <div className="form-input-wrapper">
                                    <div className="input-wrapper deposit-input-amount-wrapper dash-sect-amount-wrapper">
                                        <div className="deposit-usd-tag">USD</div>
                                        <input 
                                            type="text"
                                            value={depositAmount}
                                            onChange={(e) => handleDepositAmountChange(e)}
                                            onInput={(e) => handleDepositAmountInput(e)}
                                            placeholder="0.00"
                                        />
                                    </div>
                                    <div className="deposit-input-amount-error input-error dfie">{/*Dashboard form input error; dfie*/}
                                        Please enter a right amount
                                    </div>
                                </div>
                                <p className="deposit-form-note pre-button-note">
                                    Coin Stack Capital does not accept third party payments. I confirm that my account name with Equiti matches the account holder name on my used payment method.
                                </p>
                                <button 
                                    type="button"  
                                    className="pre-deposit-button form-button" 
                                    onClick={(e)=>handlePreBtn(e)}
                                >
                                    Continue
                                </button>
                            </div>

                            {/*<!--Form to display where to deposit-->*/}
                            <div className="pay-selection-dropdown-wrapper">
                                <div 
                                    className="psdw-back-button"
                                    onClick={(e)=>handlePsdwBackBtn(e)}
                                >
                                    back
                                </div>
                                <h2>Choose an option</h2>

                                <div className="payment-dropdown">
                                    <button 
                                        className="dropbtn"
                                        onClick={(e)=>handleDropdownBtn(e)}
                                    >
                                        <span 
                                            className="dropbtntitle"
                                            ref={dropBtnTitle}
                                        >
                                            Select a payment method
                                        </span>
                                        <span className="dropbtn-drop-icon"></span>
                                    </button>
                                    <div 
                                        className="dropdown-content"
                                        ref={dropdownContent}
                                    >
                                        <li 
                                            id="zelle" 
                                            className="dropdown-entry"
                                            onClick={(e)=>handleDropdownEntry(e)}
                                            >
                                            Zelle
                                        </li>
                                        <li 
                                            id="cashapp" 
                                            className="dropdown-entry"
                                            onClick={(e)=>handleDropdownEntry(e)}
                                            >
                                            Cashapp
                                        </li>
                                        <li 
                                            id="bitcoin" 
                                            className="dropdown-entry"
                                            onClick={(e)=>handleDropdownEntry(e)}
                                            >
                                            Bitcoin
                                        </li>
                                        <li 
                                            id="usdt" 
                                            className="dropdown-entry"
                                            onClick={(e)=>handleDropdownEntry(e)}
                                        >
                                            Usdt
                                        </li>
                                    </div>
                                </div>

                                <div className="pay-display-wrapper">
                                    <div className="zelle-pay-wrapper option-pay-wrapper" id="zelle-wrapper">
                                    <div className="zelle-pay-wrapper-heading pay-wrapper-heading">
                                        Pay with Zelle
                                    </div>
                                    <div className="zelle-img pay-img" />
                                    <div className="amount-to-deposit">
                                            <span className="amd1">
                                                Amount to deposit: 
                                            </span>
                                            <span className="amd2">
                                                {depositAmount}USD
                                            </span>
                                    </div>
                                    <div className="zelle-acc-no transfer-to-no">
                                        <div id="contentBox">
                                        <h4>Zelle account number</h4>
                                        <div className="content-to-copy">
                                            <div className="copy-content">2163129624</div>
                                            <button 
                                                className="copy-button" 
                                                id="copyButton"
                                                onClick={(e)=>handleCopyBtn(e)}
                                            >
                                                Copy
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                    <button 
                                        className="payed"
                                        onClick={(e)=>handlePaidBtn(e)}
                                    >
                                        I have paid
                                    </button>
                                    <div className="option-foot-note">
                                        NB: Please do not type in the recipient address, rather copy it.
                                    </div>
                                    </div>
                                    <div className="cashapp-pay-wrapper option-pay-wrapper" id="cashapp-wrapper">
                                    <div className="cashapp-pay-wrapper-heading pay-wrapper-heading">
                                        Pay with Cashapp
                                    </div>
                                    <div className="cashapp-img pay-img" />
                                    <div className="amount-to-deposit">
                                            <span className="amd1">
                                                Amount to deposit: 
                                            </span>
                                            <span className="amd2">
                                                {` ${depositAmount}`} USD
                                            </span>
                                    </div>
                                    <div className="cashapp-acc-no transfer-to-no">
                                        <div id="contentBox">
                                        <h4>Cashapp account</h4>
                                        <div className="content-to-copy">
                                            <div className="copy-content">$donu123</div>
                                            <button 
                                                className="copy-button" 
                                                id="copyButton"
                                                onClick={(e)=>handleCopyBtn(e)}
                                            >
                                                Copy
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                    <button 
                                        className="payed"
                                        onClick={(e)=>handlePaidBtn(e)}
                                    >
                                        I have paid
                                    </button>
                                    <div className="option-foot-note">
                                        NB: Please do not type in the recipient address, rather copy it.
                                    </div>
                                    </div>
                                    <div className="bitcoin-pay-wrapper option-pay-wrapper" id="bitcoin-wrapper">
                                    <div className="bitcoin-pay-wrapper-heading pay-wrapper-heading">
                                        Pay with Crypto (Bitcoin)
                                    </div>
                                    <div className="bitcoin-img pay-img" />
                                    <div className="amount-to-deposit">
                                            <span className="amd1">
                                                Amount to deposit: 
                                            </span>
                                            <span className="amd2">
                                                {` ${depositAmount}`} USD
                                            </span>
                                    </div>
                                    <div className="bitcoin-acc-no transfer-to-no">
                                        <div id="contentBox">
                                        <h4>Bitcoin address</h4>
                                        <div className="content-to-copy">
                                            <div className="copy-content">
                                            3NMwT3DFft4pF8h5xmArjhCZN59YV52coS
                                            </div>
                                            <button 
                                                className="copy-button" 
                                                id="copyButton"
                                                onClick={(e)=>handleCopyBtn(e)}
                                            >
                                                Copy
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                    <button 
                                        className="payed"
                                        onClick={(e)=>handlePaidBtn(e)}
                                    >
                                        I have paid
                                    </button>
                                    <div className="option-foot-note">
                                        NB: Please do not type in the recipient address, rather copy it.
                                    </div>
                                    </div>
                                    <div className="usdt-pay-wrapper option-pay-wrapper" id="usdt-wrapper">
                                        <div className="bitcoin-pay-wrapper-heading pay-wrapper-heading">
                                            Pay with Crypto (Usdt)
                                        </div>
                                        <div className="usdt-img pay-img" />
                                        <div className="amount-to-deposit">
                                                <span className="amd1">
                                                    Amount to deposit: 
                                                </span>
                                                <span className="amd2">
                                                    {` ${depositAmount}`} USD
                                                </span>
                                        </div>
                                        <div className="usdt-acc-no transfer-to-no">
                                            <div id="contentBox">
                                            <h4>Network: TRC20</h4>
                                            <div className="content-to-copy">
                                                <div className="copy-content">
                                                TD5FDoxrMKwXmqqZTbzLQkFcdHvsMGHLpX
                                                </div>
                                                <button 
                                                    className="copy-button" 
                                                    id="copyButton"
                                                    onClick={(e)=>handleCopyBtn(e)}
                                                >
                                                    Copy
                                                </button>
                                            </div>
                                            </div>
                                            <div id="contentBox">
                                            <h4>Network: ERC20</h4>
                                            <div className="content-to-copy">
                                                <div className="copy-content">
                                                0x3560b36e0bd6c0207bf7162fbcfc98c51e5cdf4a
                                                </div>
                                                <button 
                                                    className="copy-button" 
                                                    id="copyButton"
                                                    onClick={(e)=>handleCopyBtn(e)}
                                                >
                                                    Copy
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                                        <button 
                                            className="payed"
                                            onClick={(e)=>handlePaidBtn(e)}
                                        >
                                            I have paid
                                        </button>
                                        <div className="option-foot-note">
                                            NB: Please do not type in the recipient address, rather copy it.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <p className="deposit-bottom-note bottom-note">
                            Please note that if you are using a new payment method that was not used before, your deposit might not be applied immediately into your account and might take up to 24 hours to be reviewed and processed. 
                            On some occasions, we might request proof that the card or payment account is under your name.
                        </p>
                    </section>
                </div>


                {/*<!--Withdrawal content-->*/}
                <div 
                    className="withdrawal-content dashboard-sect"
                    id="withdrawal-content"
                >
                    {/* withdrawal header */}
                    <div>
                        <h3>Safe withdrawal</h3>
                    </div>
                    <section className="tab-body withdrawal-bonus-section dash-sect-header">
                        <div className="withdrawal-pre-note dash-header-note">
                            Stack Financial follows a strict return to source policy, in all instances where applicable.
                        </div>
                    </section>

                    <section className="tab-body">
                        <div className="dashboard-sect-form-wrapper">
                            <div className="dashboard-sect-form">
                                {/*withdrawal account selection*/}
                                <h4>
                                    <label>
                                        Select an Account to withdraw from
                                    </label>
                                </h4>
                                <div className="form-input-wrapper">
                                    <div className="custom-dropdown">
                                        <button 
                                            className="dropdown-toggle withdrawal-input-account-wrapper" 
                                            onClick={()=>{
                                                document.querySelector(".withdrawal-dropdown-entries").classList.toggle("show")
                                            }}
                                        >
                                            <span className="dropdown-toggle-title" lnk="withdraw-account-choice">
                                                Select a payment account
                                            </span>
                                            <span className="dropdown-toggle-icon"></span>
                                        </button>
                                        <div class="dropdown-entries withdrawal-dropdown-entries">
                                        {accounts.map((account, index) => (
                                            <li key={index} className="dropdown-entre" lnk="withdraw-account-choice" 
                                                onClick={(e)=>{
                                                    updateToggleTitle(e,account.account_name);
                                                    setAvailableWithdrawal(`${account.total_balance}`)
                                                }}>
                                                {`${account.account_name} (USD ${account.total_balance ? parseFloat(account.total_balance).toFixed(2) : "0.00"})`}
                                            </li>
                                        ))}
                                        </div>
                                        <div className="withdrawal-input-account-error input-error dfie">{/*Dashboard form input error; dfie*/}
                                        </div>
                                    </div>
                                    {/*${firstAccountName} (USD ${firstAccountBalance ? parseFloat(firstAccountBalance).toFixed(2) : "0.00"})*/}
                                    {/*"account_name Wallet (USD total_balance)"*/}
                                </div>
                                {/*withdrawal account selection*/}
                                <h4>
                                    <label>
                                        Withdrawal Amount
                                    </label>
                                </h4>
                                <div className="form-input-wrapper">
                                    <div className="input-wrapper withdrawal-input-amount-wrapper dash-sect-amount-wrapper">
                                        <div className="deposit-usd-tag">USD</div>
                                        <input 
                                            type="text"
                                            value={withdrawalAmount}
                                            onChange={(e) => handleWithdrawalAmountChange(e)}
                                            onInput={(e) => handleWithdrawalAmountInput(e)}
                                            placeholder="0.00"
                                        />
                                    </div>
                                    <div className="withdrawal-input-amount-error input-error dfie">{/*Dashboard form input error; dfie*/}
                                        Please enter a right amount
                                    </div>
                                    <div className="withdrawal-input-amount-message input-message">
                                    </div>
                                    <div className="input-message-patch input-message">
                                    </div>
                                </div>
                                <p className="withdrawal-form-note pre-button-note">
                                    All monies paid out by Stack Financial will be paid back to the source from where they originated from by default.
                                </p>
                                <button 
                                    type="button"  
                                    className="pre-withdrawal-button form-button" 
                                    onClick={(e)=>handleWithdrawalPreBtn(e)}
                                >
                                    <svg width="20" height="20" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="100%" y2="0%">
                                                <stop offset="0%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 1 }} />
                                                <stop offset="100%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 0 }} />
                                            </linearGradient>
                                        </defs>
                                        <circle cx="25" cy="25" r="20" stroke="url(#gradientStroke)" strokeWidth="4" fill="none" strokeLinecap="round"/>
                                        <animateTransform
                                            attributeName="transform"
                                            attributeType="XML"
                                            type="rotate"
                                            from="0 0 0"
                                            to="360 0 0"
                                            dur="1s"
                                            repeatCount="indefinite"/>
                                    </svg>
                                    Continue
                                </button>
                            </div>

                        </div>
                        <p className="deposit-bottom-note bottom-note">
                            Please note that if you are using a new payment method that was not used before, your deposit might not be applied immediately into your account and might take up to 24 hours to be reviewed and processed. 
                            On some occasions, we might request proof that the card or payment account is under your name.
                        </p>
                    </section>
                </div>


                {/*<!--Transfer content-->*/}
                <div 
                    className="transfer-content dashboard-sect"
                    id="transfer-content"
                >
                    {/* withdrawal header */}
                    <div>
                        <h3>Move Funds</h3>
                    </div>

                    <section className="tab-body">
                        <div className="dashboard-sect-form-wrapper">
                            <div className="transfer-form dashboard-sect-form">
                                {/* From account */}
                                <h4>
                                    <label>
                                        Select an Account to withdraw from
                                    </label>
                                </h4>
                                <div className="form-input-wrapper">
                                    <div className="custom-dropdown">
                                        <button 
                                            className="dropdown-toggle transfer-from-input-account-wrapper" 
                                            onClick={()=>{
                                                document.querySelector(".transfer-from-dropdown-entries").classList.toggle("show")
                                            }}
                                        >
                                            <span className="dropdown-toggle-title" lnk="transfer-from-account-choice">
                                                Select a payment account
                                            </span>
                                            <span className="dropdown-toggle-icon"></span>
                                        </button>
                                        <div class="dropdown-entries transfer-from-dropdown-entries">
                                        {accounts.map((account, index) => (
                                            <li key={index} className="dropdown-entre" lnk="transfer-from-account-choice" 
                                                onClick={(e)=>{
                                                    updateToggleTitle(e,account.account_name);
                                                    setAvailableTransfer(`${account.total_balance}`)
                                                }}>
                                                {`${account.account_name} (USD ${account.total_balance ? parseFloat(account.total_balance).toFixed(2) : "0.00"})`}
                                            </li>
                                        ))}
                                        </div>
                                        <div className="transfer-from-input-account-error input-error dfie">{/*Dashboard form input error; dfie*/}
                                        </div>
                                    </div>
                                </div>

                                {/* To account */}
                                <h4>
                                    <label>
                                        Select an Account to withdraw to
                                    </label>
                                </h4>
                                <div className="form-input-wrapper">
                                    <div className="custom-dropdown">
                                        <button 
                                            className="dropdown-toggle transfer-to-input-account-wrapper" 
                                            onClick={()=>{
                                                document.querySelector(".transfer-to-dropdown-entries").classList.toggle("show")
                                            }}
                                        >
                                            <span className="dropdown-toggle-title" lnk="transfer-to-account-choice">
                                                Select a payment account
                                            </span>
                                            <span className="dropdown-toggle-icon"></span>
                                        </button>
                                        <div class="dropdown-entries transfer-to-dropdown-entries">
                                        {accounts.map((account, index) => (
                                            <li key={index} className="dropdown-entre" lnk="transfer-to-account-choice" 
                                                onClick={(e)=>{
                                                    updateToggleTitle(e,account.account_name);
                                                }}>
                                                {`${account.account_name} (USD ${account.total_balance ? parseFloat(account.total_balance).toFixed(2) : "0.00"})`}
                                            </li>
                                        ))}
                                        </div>
                                        <div className="transfer-to-input-account-error input-error dfie">{/*Dashboard form input error; dfie*/}
                                        </div>
                                    </div>
                                </div>

                                {/* Transfer amount */}
                                <h4>
                                    <label>
                                        Tranfer Amount
                                    </label>
                                </h4>
                                <div className="form-input-wrapper">
                                    <div className="input-wrapper transfer-input-amount-wrapper dash-sect-amount-wrapper">
                                        <div className="deposit-usd-tag">USD</div>
                                        <input 
                                            type="text"
                                            value={transferAmount}
                                            onChange={(e) => handleTransferAmountChange(e)}
                                            onInput={(e) => handleTransferAmountInput(e)}
                                            placeholder="0.00"
                                        />
                                    </div>
                                    <div className="transfer-input-amount-error input-error dfie">{/*Dashboard form input error; dfie*/}
                                        Please enter a right amount
                                    </div>
                                </div>

                                <button 
                                    type="button"  
                                    className="pre-transfer-button form-button" 
                                    onClick={(e)=>handleTransferPreBtn(e)}
                                >
                                    <svg width="20" height="20" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="100%" y2="0%">
                                                <stop offset="0%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 1 }} />
                                                <stop offset="100%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 0 }} />
                                            </linearGradient>
                                        </defs>
                                        <circle cx="25" cy="25" r="20" stroke="url(#gradientStroke)" strokeWidth="4" fill="none" strokeLinecap="round"/>
                                        <animateTransform
                                            attributeName="transform"
                                            attributeType="XML"
                                            type="rotate"
                                            from="0 0 0"
                                            to="360 0 0"
                                            dur="1s"
                                            repeatCount="indefinite"/>
                                    </svg>
                                    Continue
                                </button>
                            </div>

                        </div>
                    </section>
                </div>


                {/*<!--Report content-->*/}
                <div 
                    className="dashboard-sect"
                    id="report-content"
                >
                    <div>
                        <h3>Reports</h3>
                    </div>

                    <section className="tab-body dash-sect-header">
                        <div className="reports-pre-note dash-header-note">
                            You have no reports at the moment.
                        </div>
                    </section>
                    {/*
                    <section className="tab-body">
                        <div className="dashboard-sect-form-wrapper">
                            <div className="deposit-form dashboard-sect-form">
                                <h4>
                                    <label>
                                    </label>
                                </h4>
                                <div className="form-input-wrapper">
                                    <div className="input-wrapper">
                                        <input 
                                            type="text"
                                            value="Please select a report"
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <button type="button"  className="signin-button form-button">
                                    Continue
                                </button>
                            </div>
                        </div>
                    </section>
                    */}
                </div> 

                {/*<!--analysis content-->*/}
                <div 
                    className="dashboard-sect"
                    id="analysis-content"
                >
                    {newsData ? (
                        <Analysis newsData={newsData} />
                    ) : (
                        <div className="analysis-loader">Loading Analytics...</div>
                    )}
                </div>

                {/*<!--Trading platform content-->*/}
                <div 
                    className="dashboard-sect"
                    id="trading-platforms-content"
                >
                    <div>
                        <h3>Trading Platforms</h3>
                    </div>
                    
                    <section className="tab-body">
                        <div className="platform-wrapper-parentII">
                            <div className="">
                                <h3>Web Platform</h3>
                                <div className="mqtrader-platform-wrapper">
                                    <div className="mq-preview">
                                    {/* Intended for future content or styling */}
                                    </div>
                                    <div className="mq-text">
                                    <h2>MQ WebTrader</h2>
                                    <p>
                                        MQ WebTrader is a web version of the MT4/MT5 platforms. 
                                        The MQ WebTrader allows you to analyze markets and execute trades directly from your browser.
                                    </p>
                                    <ul>
                                        <li>No installation is needed.</li>
                                        <li>Trade from any device via any browser.</li>
                                        <li>Encrypted secure connection.</li>
                                    </ul>
                                    <p>*Can be added to your iPhone's/iPad's home screen.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="bottom-note">
                            Most of our platforms are currently undergoing active maintenance. This temporary measure is crucial to ensure that we maintain the highest levels of security and performance across all our services.
                        </p>
                    </section>
                </div>

                {/*<!--referral content-->*/}
                <ReferralComponent refCode={refCode} totalEarnings={totalEarnings} totalInvited={totalInvited} filterData={filterData}/>

                {/*<!--documents-->*/}
                <div className="dashboard-sect documents-sect" id="documents-content">

                    <div>
                        <h3>Documents</h3>
                    </div>
                    {/*<!--id section-->*/}
                    {
                        documents?
                        documents.map((entry, index) => {
                            const documentType = Object.keys(entry)[0];
                            const documentTypeName = documentType === 'id_document' ? 'Id Card' : 'Passport';
                        
                            return (
                                <div>
                                    <section key={index} className="tab-body dash-sect-header">
                                        <div className="dash-header-note dash-id-header-note">                            
                                            <div className="documents-dihi"></div> {/* <!--id header icon--> */}
                                            {documentTypeName}
                                        </div>
                                    </section>
                                    <section className="tab-body documents-tab-body">
                                        <div className="account-summary documents-entry-wrapper">
                                            <div className="account-summary-entry documents-entry documents-dnw"> {/* <!--document name wrapper--> */}
                                                <div className="document-entry-header">Document name</div>
                                                <div className="document-sub-entry name-dse approved"> {/* <!--name document sub entry--> */}
                                                    {entry[documentType].name.map((name, index) => (
                                                        <div key={index}>
                                                            {name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="account-summary-entry documents-entry documents-dw"> {/* <!--document date wrapper--> */}
                                                <div className="document-entry-header">Date uploaded</div>
                                                <div className="document-sub-entry">
                                                    <div>
                                                        {new Date(entry[documentType].date_uploaded).toLocaleDateString()}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="account-summary-entry documents-entry documents-sw"> {/* <!--document status wrapper--> */}
                                                <div className="document-entry-header">Status</div>
                                                <div className={`document-sub-entry status-dse ${entry[documentType].status.toLowerCase()}`}>
                                                    <div>{entry[documentType].status}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            );
                        }):(
                            <section className="tab-body dash-sect-header">
                                <div className="dash-header-note dash-id-header-note">                            
                                    You have no documents uploaded yet.
                                </div>
                            </section>
                        )
                    }
                </div>
            </div>

        </main>
    </div>
  );
};

export default Dashboard;
