import React from 'react';

import visa from "../../component_media/visa.svg"
import mastercard from "../../component_media/mastercard.svg"
import skrill from "../../component_media/skrill.svg"
import neteller from "../../component_media/neteller.svg"
import facebook_icon from "../../component_media/facebook_icon.svg"
import twitterx_icon from "../../component_media/TwitterX_icon.svg"
import instagram_icon from "../../component_media/instagram_icon.svg"
import youtube_icon from "../../component_media/youtube_icon.svg"
import linkedin_icon from "../../component_media/linkedin_icon.svg"

const FooterComponent = () => {
    return(
        <footer>
            <section className="global-broker no-wrap912 sec-tion">
                <div className="text-content">
                    <div className="hero-mini-tag mini-tag">GLOBAL BROKER</div>
                    <div role="heading" className="l1-heading heading">Start trading online with Stack Financial</div>
                    {/* Button */}
                    <button className="butt start-now-button" role="button" aria-label="Start now">
                        <a href="/dashboard" className="contact-link" role="link">
                            <div className="inner-butt">
                                START NOW
                            </div>
                        </a>
                    </button>
                    <div>
                    </div>
                    <div className="gateway-option">
                        <img src={visa} alt="Visa Logo" style={{height: "auto"}} />
                        <img src={mastercard} alt="Mastercard Logo" style={{height: "auto"}} />
                        <img src={skrill} alt="Skrill Logo" style={{height: "auto"}} />
                        <img src={neteller} alt="Neteller Logo" style={{height: "auto"}} />
                    </div>
                </div>
            </section>
            <section className="social-band sec-tion">
                <h1 className="top-header">Stack Financial</h1>
                <div className="social-links">
                    <button className="butt start-now-button" role="button" aria-label="Start now">
                        <a href="" className="contact-link" role="link">
                            <img className="inner-butt" src={facebook_icon} style={{height: "auto"}} />
                        </a>
                    </button>
                    <button className="butt start-now-button" role="button" aria-label="Start now">
                        <a href="" className="contact-link" role="link" style={{height: "auto"}}>
                            <img className="inner-butt" src={twitterx_icon} style={{height: "auto"}} />
                        </a>
                    </button>
                    <button className="butt start-now-button" role="button" aria-label="Start now">
                        <a href="" className="contact-link" role="link">
                            <img className="inner-butt" src={instagram_icon} style={{height: "auto"}} />
                        </a>
                    </button>
                    <button className="butt start-now-button" role="button" aria-label="Start now">
                        <a href="" className="contact-link" role="link">
                            <img className="inner-butt" src={youtube_icon} style={{height: "auto"}} />
                        </a>
                    </button>
                    <button className="butt start-now-button" role="button" aria-label="Start now">
                        <a href="" className="contact-link" role="link">
                            <img className="inner-butt" src={linkedin_icon} style={{height: "auto"}} />
                        </a>
                    </button>
                </div>
            </section>
            <section className="footer-links sec-tion">
                <div>
                    <h4>Products</h4>
                    <ul>
                        <li>Crypto</li>
                        <li>Indices</li>
                        <li>Commodities</li>
                        <li>Shares</li>
                        <li>ETFs</li>
                        <li>Crypto CFDs</li>
                    </ul>
                </div>
                <div>
                    <h4>Accounts</h4>
                    <ul>
                        <li>Trading accounts</li>
                    </ul>
                </div>
                <div>
                    <h4>Partnerships</h4>
                    <ul>
                        <li>Partner with us</li>
                        <li>Refer a friend</li>
                    </ul>
                </div>
                <div>
                    <h4>Support</h4>
                    <ul>
                        <li>
                            <a href="/about">
                                About Us
                            </a>
                        </li>
                        <li>Careers</li>
                    </ul>
                </div>
            </section>
            <section className="legal-doc sec-tion">
                <div className="legal-doc-head">Legal Documentation</div>
                <p>
                    *Low spreads and zero commission depend on product and account type.
                </p>
                <p>
                    Margined FX and contracts for difference are complex leveraged products which carry a high level of risk and can result in losses that exceed your initial investment. 
                    We recommend you seek professional advice before investing.
                </p>
                <p>
                    Stack Financials Limited is a company incorporated with limited liability under the laws of the Republic of Seychelles, under registration number 8428558-1, with its registered address at First Floor, Marina House, Eden Island, Republic of Seychelles.
                </p>
                <p>
                    Any analysis, opinion, commentary or research-based material on our website is for information and educational purposes only and is not, under any circumstances, intended to be an offer, recommendation, advice or solicitation to buy or sell.
                </p>
                <div>
                    © 2024 CPC, All Rights Reserved
                </div>
            </section>
        </footer>
    )
}
 
export default FooterComponent