import React, { useEffect } from 'react';
import axios from 'axios';
import '../components_styles/hash.css';
import {
    navigateTo,
    getPathAndQueryParams,
} from '../util/generic'

function Hash() {
  //const [isEnlarged, setIsEnlarged] = useState(false);
  //const [isLoading, setIsLoading] = useState(true);

  const clientArea="/client-area"
  const kycArea="/kyc"



  useEffect(() => {
    document.querySelector('.hash-container').style.height=window.innerHeight+"px"

    const url = window.location.href.includes("localhost")
    ? "http://localhost:8000/coinstackcapital/ping-session/"
    : "https://noapiere.pythonanywhere.com/coinstackcapital/ping-session/";

    // Function to fetch sesssion validity with an access token if it exists
    const accessSessionValidity = () => {
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            }
        };

        return axios.get(url, config);
    };

    // Function to refresh access token with refresh token
    const refreshSessionValidity = () => {
        const url = window.location.href.includes("localhost")
        ? "http://localhost:8000/coinstackcapital/refresh-token/"
        : "https://noapiere.pythonanywhere.com/coinstackcapital/refresh-token/";   

        const refreshToken = localStorage.getItem("refresh_token");
        const refreshUrl = url

        return axios.post(refreshUrl, { refresh: refreshToken });
    };

    accessSessionValidity()
    .then(response => {//active session
        let result = response.data.message;
        console.log(result)
        /*
         Navigate to dashboard
        */
         handleNextPath(true)
         
    })
    .catch(error => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            console.error("Error fetching data with token access:", error.response.status);

            // Unauthorized error, try refreshing access token
            refreshSessionValidity()
                .then(response => {
                    // Store new access token in localStorage
                    localStorage.setItem("access_token", response.data.access_token);

                    // Retry fetching data with the new access token
                    accessSessionValidity()
                        .then(response => {
                            let result = response.data.message;
                            console.log(result)
                            /*
                            Navigate to dashboard
                            */
                            handleNextPath(true)
                        })
                        .catch(innerError => {
                            // Handle error fetching data after token refresh
                            console.error("Error fetching data after accessed token refreshed:", innerError);
                            /*
                            Check the url and know the appropriate action to carry out
                            */
                            handleNextPath(false) 
                        });
                })
                .catch(refreshError => {//invalid refresh token
                    console.error("Error refreshing access token:", refreshError);
                    // Handle error refreshing access token
                    /*
                    Check the url and know the appropriate action to carry out
                    */
                    handleNextPath(false)
                });
        } else {
            console.error("Error fetching data:", error);
            // Handle other errors
            handleNextPath(false)
        }
    });

  }, []);

  const displayInvalidSessionNote = () => {
    document.querySelector(".hash-header").classList.remove("hash-header-pulse");
    document.querySelector(".expired-session-wrapper").style.display="block"
    document.querySelector(".hash-progress-container").style.display="none"
  }

  const handleNextPath = (session) => {
    const path = getPathAndQueryParams()  
    if (/\/dashboard/.test(path)){//If the user wants to acess the dashboard
        if(session){
            navigateTo(clientArea)
        }else{//Display the invalid session note
            displayInvalidSessionNote()
        }
    }
    else if(/\/path\?ref=[0-9a-f]+/.test(path)){//If a referral was made
        if(session){
            navigateTo(clientArea)
        }else{
            navigateTo(`/referral${window.location.search}`)
        }
    }else if(/\/know-your-customer/.test(path)){
        if(session){
            navigateTo(kycArea)
        }else{
            displayInvalidSessionNote()
        }
    }
  }

  return (
    <div className="hash-container">
        <div className="hash-header hash-header-pulse">
            Stack Financial
        </div>
        <div className="hash-progress-container">
            <div className="hash-progress"></div>
        </div>
        <div className="expired-session-wrapper">
                <div className="exp-message">
                    Your session is invalid click the signin button below to continue
                </div>
                <a href="/signin">
                    <button
                        type="button"
                        className="signin-button form-button"
                    >
                        Sign in
                    </button>
                </a>
            </div>
    </div>
  );
}

export default Hash;
