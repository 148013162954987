import React,{useState,useEffect} from  'react';
import axios from 'axios';
import '../../components_styles/kyc.css';
import {
    navigateTo,
    toggleErrorDisplay
} from '../../util/generic'


const KycComponent = () => {
    const [uploadButtonStatus, setUploadButtonStatus] = useState(false);

    const [idcardUploadMedium,setIdcardUploadMedium] = useState(false)
    const [passportUploaded,setPassportUploaded] = useState(false)
    const [idFront,setIdFront] = useState(false)
    const [idBack,setIdBack] = useState(false)

    //setRadioValues(results);
    useEffect(()=>{
        document.querySelector('.main').style.minHeight=window.innerHeight+"px"
    },[])

    const toggleLoader = (selector,display,status) =>{
        document.querySelector(selector).firstElementChild.style.display=display;
        if (display==="none"){
            document.querySelector(selector).style.filter="grayscale(0)"
        }else{
            document.querySelector(selector).style.filter="grayscale(1)"
        }
        setUploadButtonStatus(status)
    }

    const isAtLeastOneChecked = () => {
        // Get all radio-wrapper elements
        const radioWrappers = document.querySelectorAll('.radio-wrapper');

        // Loop through each radio-wrapper
        for (const wrapper of radioWrappers) {
            // Get all radio buttons inside the current radio-wrapper
            const radioButtons = wrapper.querySelectorAll('input[type="radio"]');

            // Check if at least one radio button is checked
            const isChecked = Array.from(radioButtons).some(radioButton => radioButton.checked);

            // If at least one radio button is checked, return true
            if (isChecked) {
                return true;
            }
        }

        // If no radio button is checked in any group, return false
        return false;
    }

    const getRadioWrapperValues = () => {
        // Initialize an empty object to store the results
        const results = {};

        // Get all radio-wrapper elements
        const radioWrappers = document.querySelectorAll('.radio-wrapper');

        // Loop through each radio-wrapper
        radioWrappers.forEach(wrapper => {
            // Get the name of the radio group
            const groupName = wrapper.querySelector('input[type="radio"]').name;

            // Get all radio buttons inside the current radio-wrapper
            const radioButtons = wrapper.querySelectorAll('input[type="radio"]');

            // Find the checked radio button
            const checkedRadioButton = Array.from(radioButtons).find(radioButton => radioButton.checked);

            // If a checked radio button is found, add its name and value to the results object
            if (checkedRadioButton) {
                results[groupName] = checkedRadioButton.value;
            }
        });

        return results;
    }
    
    const handleAnswerCheck = (e) =>{
        document.querySelector(".k-input-error").style.display="none"
        e.target.firstElementChild.style.display="block"

        if(isAtLeastOneChecked()){
            //Hide the question section
            document.querySelector(".questions").style.display="none"
            //Indicate that the question test has been passed
            document.querySelector(".kde-entre").classList.add("kde-entre--passed")
            document.querySelector(".kde-entre-status").textContent="✔"
            //Display the documents upload action and remove the disabled attribute from its status
            document.querySelector(".documents").style.display="flex"
            document.querySelectorAll(".kde-entre")[1].classList.remove("kde-entre--disabled")
            //style the status indicator
            const kycCounter=document.querySelector(".kyc-counter")
            kycCounter.style.borderRightColor="green"; 
            kycCounter.style.borderBottomColor="green"; 
            kycCounter.textContent="1/2"
        }else{
            document.querySelector(".k-input-error").style.display="block"
        }
    }

    const handleFilePicker = (e) =>{
        const fileInput=e.currentTarget.nextElementSibling
        const fileName=fileInput.nextElementSibling
        fileInput.click();

        fileInput.addEventListener('change', function() {
            if (fileInput.files.length > 0) {
                fileName.value = fileInput.files[0].name;
            } else {
                fileName.value = '';
            }
        });
    }

    const handlePrevBtn = () =>{
        document.querySelector(".documents-um").style.display="none"
        document.querySelector(".documents").style.display="flex"
    }
    
    const handleUploadAction = (e) => {
        document.querySelector(".documents").style.display="none"
        document.querySelector(".documents-um").style.display="flex"
        //setIdcardUploadMedium(true)
        if(e.currentTarget["id"]==="identity-card-action"){
            setIdcardUploadMedium(true)
        }else{
            setIdcardUploadMedium(false)
        }
    }


    const toggleDropDown = () => {
        document.querySelector(".kde").classList.toggle("show");
    }

    const handlePassportUploaded = () =>{
        setPassportUploaded(true)
    }

    const handleIdFront = () =>{
        setIdFront(true)
    }

    const handleIdBack = () =>{
        setIdBack(true)
    }

    const getFormDataWithAnswers = () =>{
        const answers = getRadioWrapperValues()
        const formData = new FormData();
        for (const [key, value] of Object.entries(answers)) {
            formData.append(`${key}`, `${value}`);
        }
        return formData   
    }

    const handlePassportUpload = () =>{
        if(!uploadButtonStatus){
            //display the loader and set the button to true; active
            toggleLoader(
                '.passport-upload-button',
                'block',
                true
            )
            const formData = getFormDataWithAnswers()
            formData.append('case','passport')
            formData.append('passport',document.getElementById("passport-input").files[0])
            //remove the loader and set the button to false; inactive
            toggleLoader(
                '.passport-upload-button',
                'none',
                false
            )
            //send the data
            uploadKycData(formData)
        }
    }

    const handleIdUpload = () =>{
        if(!uploadButtonStatus){
            //display the loader and set the button to true; active
            toggleLoader(
                '.id-upload-button',
                'block',
                true
            )
            const formData = getFormDataWithAnswers()
            formData.append('case','id')
            formData.append('id-card-front',document.getElementById("id-front-input").files[0])
            formData.append('id-card-back',document.getElementById("id-back-input").files[0])
            //send the data
            uploadKycData(formData)
        }
    }

    const hideLoader = () => {
        if(idFront&&idBack){//Id card loader button
            //Hide the loader
            toggleLoader(
                '.id-upload-button',
                'none',
                false
            )
        }else if(passportUploaded){
            //Hide the loader
            toggleLoader(
                '.passport-upload-button',
                'none',
                false
            )

        }
    }

    // Function to refresh access token with refresh token
    const uploadKycData = (postData) => {
        //remove all possible errors
        toggleErrorDisplay(
            ['.passport-upload-error','.id-upload-error'],
            ['',''],
            ['none','none']
        )
        const uploadAction = () => {
            const url = window.location.href.includes("localhost")
            ? "http://localhost:8000/coinstackcapital/kyc-upload/"
            : "https://noapiere.pythonanywhere.com/coinstackcapital/kyc-upload/";   

            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                }
            };

    
            return axios.post(url,postData,config);
        };
    
        uploadAction()
            .then(() => {
                //Hide the loader
                hideLoader()

                //display the success message and navigate to the client area
                document.querySelector('.documents-um').style.display='none'
                document.querySelector('.k-success-message').style.display='block'
                setTimeout(()=>{
                    navigateTo('/dashboard')
                }, 3000)
            })
            .catch(error => {
                if (error.response && (error.response.status === 401)){
                    navigateTo('/know-your-customer')
                }else{
                    //remove the loader and set the button status to false; inactive
                    hideLoader()
                    //Display errors
                    if(setIdcardUploadMedium){
                        //Display the error
                        toggleErrorDisplay(
                            ['.id-upload-error'],
                            ['An error occured'],
                            ['block']
                        )
                    }else{
                        //Display the error
                        toggleErrorDisplay(
                            ['.passport-upload-error'],
                            ['An error occured'],
                            ['block']
                        )
                    }
                }
            });
    }

    return(
        <div>
            <div className="sk-top-bar">
                {/* sk=>Signup kyc */}
                <a href="/">
                    <h1 className="sk-top-header">Stack Financial</h1>
                </a>
                <div className="language-toggle-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                        <path d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.35 16.92 6ZM10 2.04C10.83 3.24 11.48 4.57 11.91 6H8.09C8.52 4.57 9.17 3.24 10 2.04ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12H2.26ZM3.08 14H6.03C6.35 15.25 6.81 16.45 7.41 17.56C5.57 16.93 4.04 15.66 3.08 14ZM6.03 6H3.08C4.04 4.34 5.57 3.07 7.41 2.44C6.81 3.55 6.35 4.75 6.03 6ZM10 17.96C9.17 16.76 8.52 15.43 8.09 14H11.91C11.48 15.43 10.83 16.76 10 17.96ZM12.34 12H7.66C7.57 11.34 7.5 10.68 7.5 10C7.5 9.32 7.57 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12H14.36Z" fill="#ABABAB"/>
                    </svg>
                    <span className="ng-binding">English</span>
                </div>
            </div>
            <div className="main">
                <div className="kyc-pane">
                    <div className="kcd">
                        <button className="kdt" onClick={toggleDropDown}>
                            <div className="dropdown-toggle-title kdtt" lnk="kyc-step">
                                Complete the profile verification to remove all limitations on depositing and trading.
                            </div>
                            <div className="kdti-wrapper">
                                <div className="kyc-counter">0/2</div>
                                <div className="dropdown-toggle-icon kdti"></div>
                            </div>
                        </button>
                        <div className="kde">
                            <li className="kde-entre" lnk="kyc-step">
                                <div className="kde-entre-text">Profile</div>
                                <div className="kde-entre-status"></div>
                            </li>
                            <li className="kde-entre kde-entre--disabled" lnk="kyc-step">
                                <div className="kde-entre-text">Verify Documents</div>
                                <div className="kde-entre-status"></div>
                            </li>
                        </div>
                    </div>
                    <div className="kyc-interations">
                        <div className="kyc-note">
                            Complete the profile verification to remove all limitations on depositing and trading.
                        </div>
                        {/*<!--Questions-->*/}
                        <div className="questions">
                            <div className="question-entry">
                                <div className="question-heading">Trading Experience</div>
                                <div className="question-sub-heading">Have you traded CFDs or Forex before?</div>
                                <div className="radio-wrapper">
                                    {/*<!-- Yes Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="tradedCFDsorForexYes"
                                            name="tradedCFDsorForex"
                                            className="custom-radio"
                                            value="yes"
                                            defaultChecked
                                        />
                                        <label htmlFor="tradedCFDsorForexYes">Yes</label>
                                    </div>
                                    {/*<!-- No Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="tradedCFDsorForexNo"
                                            name="tradedCFDsorForex"
                                            className="custom-radio"
                                            value="no"
                                        />
                                        <label htmlFor="tradedCFDsorForexNo">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="question-entry">
                                <div className="question-heading">Employment and financial background</div>
                                
                                {/*<!--Employment status-->*/}
                                <div className="question-sub-heading">Employment Status</div>
                                <div className="radio-wrapper">
                                    {/*<!-- Employed Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="employment-status-employed"
                                            name="employment-status"
                                            className="custom-radio"
                                            value="Employed"
                                            defaultChecked
                                        />
                                        <label htmlFor="employment-status-employed">Employed</label>
                                    </div>
                                    {/*<!-- Self Employed Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="employment-status-self-employed"
                                            name="employment-status"
                                            className="custom-radio"
                                            value="Self Employed"
                                        />
                                        <label htmlFor="employment-status-self-employed">Self Employed</label>
                                    </div>
                                    {/*<!-- Retired Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="employment-status-retired"
                                            name="employment-status"
                                            className="custom-radio"
                                            value="Retired"
                                        />
                                        <label htmlFor="employment-status-retired">Retired</label>
                                    </div>
                                    {/*<!-- Unemployed Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="employment-status-unemployed"
                                            name="employment-status"
                                            className="custom-radio"
                                            value="Unemployed"
                                        />
                                        <label htmlFor="employment-status-unemployed">Unemployed</label> 
                                    </div>
                                    {/*<!-- Student Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="employment-status-student"
                                            name="employment-status"
                                            className="custom-radio"
                                            value="Student"
                                        />
                                        <label htmlFor="employment-status-student">Student</label>
                                    </div>
                                </div>

                                {/*<!--Annual income question-->*/}
                                <div className="question-sub-heading">Annual Income</div>
                                <div className="radio-wrapper">
                                    {/*<!-- Below $10,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="below-$10,000"
                                            name="annual-income"
                                            className="custom-radio"
                                            value="Below $10,000"
                                            defaultChecked
                                        />
                                        <label htmlFor="below-$10,000">Below $10,000</label>
                                    </div>
                                    {/*<!-- $10,000 - $50,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="$10,000-$50,000"
                                            name="annual-income"
                                            className="custom-radio"
                                            value="$10,000-$50,000"
                                        />
                                        <label htmlFor="$10,000-$50,000">$10,000 - $50,000</label>
                                    </div>
                                    {/*<!-- $50,000 - $250,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="$50,000-$250,000"
                                            name="annual-income"
                                            className="custom-radio"
                                            value="$50,000-$250,000"
                                        />
                                        <label htmlFor="$50,000-$250,000">$50,000 - $250,000</label>
                                    </div>
                                    {/*<!-- $250,000 - $500,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="$250,000-$500,000"
                                            name="annual-income"
                                            className="custom-radio"
                                            value="$250,000-$500,000"
                                        />
                                        <label htmlFor="$250,000-$500,000">$250,000 - $500,000</label>
                                    </div>
                                    {/*<!-- $500,000 - $1,000,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="$500,000-$1,000,000"
                                            name="annual-income"
                                            className="custom-radio"
                                            value="$500,000-$1,000,000"
                                        />
                                        <label htmlFor="$500,000-$1,000,000">$500,000 - $1,000,000</label>
                                    </div>
                                    {/*<!-- Above $1,000,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="Above-$1,000,000"
                                            name="annual-income"
                                            className="custom-radio"
                                            value="Above $1,000,000"
                                        />
                                        <label htmlFor="Above-$1,000,000">Above $1,000,000</label>
                                    </div>
                                </div>

                                {/*<!--Your Total Net Worth (USD) question-->*/}
                                <div className="question-sub-heading">Your Total Net Worth (USD)</div>
                                <div className="radio-wrapper">
                                    {/*<!-- Below $500 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="tnw-below-$500"
                                            name="total-net-worth"
                                            className="custom-radio"
                                            value="Below $500"
                                            defaultChecked
                                        />
                                        <label htmlFor="tnw-below-$500">Below $500</label>
                                    </div>
                                    {/*<!-- $500 - $5,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="tnw-$500-$5,000"
                                            name="total-net-worth"
                                            className="custom-radio"
                                            value="$500-$5,000"
                
                                        />
                                        <label htmlFor="tnw-$500-$5,000">$500 - $5,000</label>
                                    </div>
                                    {/*<!-- $5,000 - $50,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="tnw-$5,000-$50,000"
                                            name="total-net-worth"
                                            className="custom-radio"
                                            value="$5,000-$50,000"
                
                                        />
                                        <label htmlFor="tnw-$5,000-$50,000">$5,000 - $50,000</label>
                                    </div>
                                    {/*<!-- $50,000 - $250,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="tnw-$50,000-$250,000"
                                            name="total-net-worth"
                                            className="custom-radio"
                                            value="$50,000-$250,000"
                
                                        />
                                        <label htmlFor="tnw-$50,000-$250,000">$50,000 - $250,000</label>
                                    </div>
                                    {/*<!-- $250,000 - $500,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="tnw-$250,000-$500,000"
                                            name="total-net-worth"
                                            className="custom-radio"
                                            value="$250,000-$500,000"
                
                                        />
                                        <label htmlFor="tnw-$250,000-$500,000">$250,000 - $500,000</label>
                                    </div>
                                    {/*<!-- $500,000 - $1,000,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="tnw-$500,000-$1,000,000"
                                            name="total-net-worth"
                                            className="custom-radio"
                                            value="$500,000-$1,000,000"
                                        />
                                        <label htmlFor="tnw-$500,000-$1,000,000">$500,000 - $1,000,000</label>
                                    </div>
                                    {/*<!-- Above $1,000,000 Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="tnw-Above-$1,000,000"
                                            name="total-net-worth"
                                            className="custom-radio"
                                            value="Above $1,000,000"
                
                                        />
                                        <label htmlFor="tnw-Above-$1,000,000">Above $1,000,000</label>
                                    </div>
                                </div>

                                {/*<!--Source of wealth question-->*/}
                                <div className="question-sub-heading">Source of wealth</div>
                                <div className="radio-wrapper">
                                    {/*<!-- Employment income Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="employment-income"
                                            name="source-of-wealth"
                                            className="custom-radio"
                                            value="Employment income"
                                            defaultChecked
                                        />
                                        <label htmlFor="employment-income">Employment income</label>
                                    </div>
                                    {/*<!-- Savings, investments, or pension Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="savings-investments-or-pension"
                                            name="source-of-wealth"
                                            className="custom-radio"
                                            value="Savings/investments/pension"
                                        />
                                        <label htmlFor="savings-investments-or-pension">Savings, investments, or pension</label>
                                    </div>
                                    {/*<!-- Inheritance Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="inheritance"
                                            name="source-of-wealth"
                                            className="custom-radio"
                                            value="Inheritance"
                                        />
                                        <label htmlFor="inheritance">Inheritance</label>
                                    </div>
                                    {/*<!-- Third party Option -->*/}
                                    <div class="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="third-party"
                                            name="source-of-wealth"
                                            className="custom-radio"
                                            value="Third party"
                                        />
                                        <label htmlFor="third-party">Third party</label>
                                    </div>
                                    {/*<!-- Grant, gift or loan or other Option -->*/}
                                    <div className="custom-radio-option">
                                        <input
                                            type="radio"
                                            id="grant-gift-or-loan-or-other"
                                            name="source-of-wealth"
                                            className="custom-radio"
                                            value="Grant/gift/loan/other"
                                        />
                                        <label htmlFor="grant-gift-or-loan-or-other">Grant, gift or loan or other</label>
                                    </div>
                                </div>
                            </div>
                            <div className="input-error k-input-error">Please check all boxes</div>
                            <button
                                type="button"
                                className="form-button"
                                onClick={(e)=>handleAnswerCheck(e)}
                            >
                                <svg width="20" height="20" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 1 }} />
                                            <stop offset="100%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 0 }} />
                                        </linearGradient>
                                    </defs>
                                    <circle cx="25" cy="25" r="20" stroke="url(#gradientStroke)" strokeWidth="4" fill="none" strokeLinecap="round"/>
                                    <animateTransform
                                        attributeName="transform"
                                        attributeType="XML"
                                        type="rotate"
                                        from="0 0 0"
                                        to="360 0 0"
                                        dur="1s"
                                        repeatCount="indefinite"
                                    />
                                </svg>
                                Continue
                            </button>
                        </div>             
                        {/*<!--Documents-->*/}
                        <div className="documents">{/*<!--Da=> Document action-->*/}
                            <div className="da-heading">We need to verify your identity</div>
                            <div className="da-sub-heading">To confirm your identity, you will need to take picture, scan or upload one of the following documents:</div>
                            <div className="document-action international-passport-action" id="international-passport-action" onClick={(e)=>handleUploadAction(e)}>
                                <div className="documents-dai international-passport-icon">{/*<!--Document action icon-->*/}
                                </div>
                                <div>
                                    <div className="action-tag">International Passport</div>
                                    <div className="action-sub-tag">The photo page of your passport.</div>
                                </div>
                                <div className="k-next-arrow"></div>
                            </div>
                            <div className="document-action  identity-card-action" id="identity-card-action" onClick={(e)=>handleUploadAction(e)}>
                                <div className="documents-dai id-card-icon"></div>
                                <div>
                                    <div className="action-tag">Identity card</div>
                                    <div className="action-sub-tag">Both sides of your national id card.</div>
                                </div>
                                <div className="k-next-arrow"></div>
                            </div>
                        </div>       
                        {/*<!--upload medium-->*/}
                        <div className="upload-medium documents-um">
                            <div>
                                <div className="document-prev-butt" onClick={handlePrevBtn}>
                                </div>
                            </div>
                            {idcardUploadMedium===false?
                                (
                                    <div className="passport-um">
                                        <div className="da-heading">Upload International Passport</div>
                                        <div className="k-upload-butt-wrapper"> {/*<!--ub => Upload button -->*/}
                                            <button type="button" className="k-upload-butt" onClick={(e)=>handleFilePicker(e)}>
                                                <div className="ub-icon">
                                                </div>
                                                <div>
                                                    Upload file
                                                </div>
                                            </button>
                                            <input type="file" id="passport-input" className="file-input" accept="image/*,.pdf"  style={{display: "none"}} onChange={handlePassportUploaded}/>
                                            <input type="text" className="file-name" readonly placeholder="No file selected"/>
                                        </div>
                                        <div className="input-error passport-upload-error">Please check all boxes</div>
                                        {
                                            passportUploaded?(
                                                <button
                                                    type="button"
                                                    className="form-button passport-upload-button"
                                                    onClick={handlePassportUpload}
                                                >
                                                    <svg width="20" height="20" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                                                        <defs>
                                                            <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="100%" y2="0%">
                                                                <stop offset="0%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 1 }} />
                                                                <stop offset="100%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 0 }} />
                                                            </linearGradient>
                                                        </defs>
                                                        <circle cx="25" cy="25" r="20" stroke="url(#gradientStroke)" strokeWidth="4" fill="none" strokeLinecap="round"/>
                                                        <animateTransform
                                                            attributeName="transform"
                                                            attributeType="XML"
                                                            type="rotate"
                                                            from="0 0 0"
                                                            to="360 0 0"
                                                            dur="1s"
                                                            repeatCount="indefinite"
                                                        />
                                                    </svg>
                                                    Continue
                                                </button>
                                            ):""
                                        }
                                    </div>
                                ):(
                                    <div className="id-card-um">
                                        <div className="da-heading">Upload Identity Card</div>
                                        <div className="k-upload-butt-wrapper"> {/*<!--ub => Upload button -->*/}
                                            <button type="button" className="k-upload-butt" onClick={(e)=>handleFilePicker(e)}>
                                                <div className="ub-icon"></div>
                                                <div>
                                                    Upload front of Id card
                                                </div>
                                            </button>
                                            <input type="file" id="id-front-input" className="file-input" accept="image/*,.pdf"  style={{display: "none"}} onChange={handleIdFront}/>
                                            <input type="text" className="file-name" readonly placeholder="No file selected"/>
                                        </div>
                                        <div className="k-upload-butt-wrapper"> {/*<!--ub => Upload button -->*/}
                                            <button type="button" className="k-upload-butt" onClick={(e)=>handleFilePicker(e)}>
                                                <div className="ub-icon"></div>
                                                <div>
                                                    Upload back of Id card
                                                </div>
                                            </button>
                                            <input type="file" id="id-back-input" className="file-input" accept="image/*,.pdf"  style={{display: "none"}} onChange={handleIdBack}/>
                                            <input type="text" className="file-name" readonly placeholder="No file selected"/>
                                        </div>
                                        <div className="input-error id-upload-error">Please check all boxes</div>
                                        {
                                            (idFront&&idBack)?(
                                                <button
                                                    type="button"
                                                    className="form-button id-upload-button"
                                                    onClick={handleIdUpload}
                                                >
                                                    <svg width="20" height="20" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                                                        <defs>
                                                            <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="100%" y2="0%">
                                                                <stop offset="0%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 1 }} />
                                                                <stop offset="100%" style={{ stopColor: "rgb(255,0,0)", stopOpacity: 0 }} />
                                                            </linearGradient>
                                                        </defs>
                                                        <circle cx="25" cy="25" r="20" stroke="url(#gradientStroke)" strokeWidth="4" fill="none" strokeLinecap="round"/>
                                                        <animateTransform
                                                            attributeName="transform"
                                                            attributeType="XML"
                                                            type="rotate"
                                                            from="0 0 0"
                                                            to="360 0 0"
                                                            dur="1s"
                                                            repeatCount="indefinite"
                                                        />
                                                    </svg>
                                                    Continue
                                                </button>
                                            ):""
                                        }
                                    </div>
                                )
                            }
                        </div>
                        {/*<!--Successful upload message-->*/}
                        <div className="k-success-message">{/*<!--ksm => kyc success message-->*/}
                            <div className="ksm-header">Sucessfully Verified</div>
                            <div className="check-mark">&#x2714;</div>
                            <div>
                                You have successfully completed your know your customer action.<br/>
                                You'll be navigated to your client area right away
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default KycComponent