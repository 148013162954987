import React from  'react';
import '../../components_styles/support.css';



const SupportComponent = () => {
    return(
        <div>
            <div className="sk-top-bar">{/*<!--sk=>Signup kyc -->*/}
                <a href="/">
                    <h1 className="sk-top-header">Stack Financial</h1>
                </a>
                <div className="language-toggle-button" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                        <path d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.35 16.92 6ZM10 2.04C10.83 3.24 11.48 4.57 11.91 6H8.09C8.52 4.57 9.17 3.24 10 2.04ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12H2.26ZM3.08 14H6.03C6.35 15.25 6.81 16.45 7.41 17.56C5.57 16.93 4.04 15.66 3.08 14ZM6.03 6H3.08C4.04 4.34 5.57 3.07 7.41 2.44C6.81 3.55 6.35 4.75 6.03 6ZM10 17.96C9.17 16.76 8.52 15.43 8.09 14H11.91C11.48 15.43 10.83 16.76 10 17.96ZM12.34 12H7.66C7.57 11.34 7.5 10.68 7.5 10C7.5 9.32 7.57 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12H14.36Z" fill="#ABABAB"/>
                    </svg>
                    <span className="ng-binding">English</span>
                </div>
            </div>
            <div className="support-post-header">
                <div className="support-hero-container">
                </div>
                <div className="support-means-wrapper">
                    <div className="support-box email-support">
                        <h2>Email Support</h2>
                        <p>For any inquiries or assistance, feel free to reach out to our support team via email</p>
                        <a href="mailto:team@stackfinancialsolutions.com" className="email-link">Inbox us</a>
                    </div>
                    <div className="support-box chat-support">
                        <h2>Chat Support</h2>
                        <p>We also offer live chat support for immediate assistance. <br/> Click the chat icon below to start a conversation with our support team.</p>
                    </div>	 
                </div>
                <div className="footer-note">
                    Trading involves a high degree of risk. Stack Financial Partners Limited is a registered 
                    trading name in Seychelles (License Number SD064) which is authorized and regulated by the 
                    Financial Services Authority with its company address at First Floor, Marina House, Eden Island, Republic of Seychelles.
                </div>
            </div>
        </div>
    )
}

export default SupportComponent