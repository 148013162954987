import React from 'react';

const Analysis= ({newsData}) => {

  return (
    <div>
      {
        Object.entries(newsData).map(([keyword, articles]) => (
            <div className="analysis-type" key={keyword}>
                <div className="analysis-type-heading analysis-ath">{keyword}</div>
                    <div className="analysis-type-sub-container analysis-atsc">
                        {articles.map((article) => (
                            <div key={article.id} className="analysis-entry">
                                <div className="analysis-entry-title">
                                    <a href={article.article_url}>{article.title}</a>
                                </div>
                                <div className="analysis-entry-image analysis-aei">
                                    <img src={article.image_url} height="100%" width="100%" alt={article.publisher.name}/>
                                </div>
                                <div className="analysis-entry-author-published-date analysis-aeapd">
                                    <div className="analysis-aeapd-author">{article.author}</div>
                                    <div className="analysis-aeapd-published-date">{article.published_utc}</div>
                                </div>
                                <div className="analysis-entry-tiker analysis-aet">
                                    {article.tickers.map((ticker, index) => (
                                        <div key={index} className="analysis-aet-entry">
                                            {ticker}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
        ))
      }
    </div>
  );
};

export default Analysis;
