const navigateTo = (url) => {
    let link=document.createElement("a")
    link["href"]=url
    link.click() 
}

const getPathAndQueryParams = () => {
    // Get the full URL of the current document
    //const fullUrl = window.location.href;
  
    // Get the path of the URL
    const path = window.location.pathname;
  
    // Get the query parameters of the URL
    const queryParams = window.location.search;
  
    // Combine path and query parameters
    const pathAndQueryParams = path + queryParams;
  
    return pathAndQueryParams;
}

const styleWrapper = (selector,state) =>{
    const elem=document.querySelector(selector)
    if(state==="valid"){
        elem.style.border="1px solid var(--valid-input-border)"
    }else if(state==="error"){
        elem.style.border="1px solid var(--err-input-border)"
    }else if(state==="neutral"){
        elem.style.border="1px solid var(--input-wrapper-border)"
    }

}

const toggleErrorDisplay = (selectors, contents, displays) => {
    for(let i = 0; i < selectors.length; i++) {
        const elem = document.querySelector(selectors[i]);
        if(elem) { // Check if the element exists
            elem.textContent = contents[i];
            elem.style.display = displays[i];
        }
    }
};

export{
    navigateTo,
    getPathAndQueryParams,
    styleWrapper,
    toggleErrorDisplay
}