import React, { useRef } from 'react';
import FooterComponent from './HomePage_Sub/Footer'
import TopBarComponent from './HomePage_Sub/TopBar'
import '../components_styles/home.css'

import heroImage from "../component_media/homepage_hero_image_webp.webp"
import percent from "../component_media/percent.svg"
import tablet from "../component_media/phone_tablet.svg"
import support_icon from "../component_media/support_icon.svg";
import trading_products from "../component_media/trading-products.original.format-webp.webp"
import step_one_open from "../component_media/step_1_open.svg"
import step_two_fund from "../component_media/step_2_fund.svg"
import step_three_trade from "../component_media/step_3_trade.svg"
import transfer_funds_securely from "../component_media/transfer-funds-securely.original.format-webp.webp"
import devices from "../component_media/devices.png"
import learn_how_to_trade from "../component_media/Learn_how_to_trade.original.format-webp.webp"
import prefooter_security from "../component_media/prefooter_security-webp.webp"
import meet_the_team from "../component_media/meet_the_team-webp.webp"
import all_products from "../component_media/all_products-webp.webp"


const Home = () => {


    return(
        <div>
            {/*<!--Top Bar-->*/}
            <TopBarComponent/>

            <section className="hero-sect no-wrap912 sec-tion">
                <div className="text-content">
                    <div className="hero-mini-tag mini-tag">TOP-RANKED TRADING PLATFORM</div>
                    <div role="heading" className="l1-heading heading">Embark on your trading and exchange journey with up to 1:2000 leverage.</div>
                    <div className="subheading">
                        Execute trades on your smartphone, tablet, or desktop, enjoying immediate entry to the markets and dedicated support from a worldwide brokerage.
                    </div>
                    <button className="butt start-now-button" role="button" aria-label="Start now">
                        <a href="/dashboard" className="contact-link" role="link">
                            <div className="inner-butt">
                                START NOW
                            </div>
                        </a>
                    </button>
                </div>
                <div className="image-content">
                    {/* Replace 'image-source.jpg' with your actual image source */}
                    <img src={heroImage} alt="Trading Platform Image" style={{ width: '100%', height: 'auto' }} />
                </div>
            </section>

            <section className="perks sec-tion">
                <div className="hero-mini-tag mini-tag">Why trade with Stack Financial</div>
                <div role="heading" className="l2-heading heading">All your online trading needs in one hi-tech dashboard</div>
                <div className="perks-tab-wrapper">
                    <div className="perks-tab">
                        <img src={percent} alt="percent icon" style={{ height: 'auto' }} />
                        <div className="tab-heading heading">Cost Kept Low</div>
                        <div className="tab-note">
                            $0 platform fees, leverage up to 1:2000 and equities at minimal amount.
                        </div>
                        <div className="tab-tag end-tag">
                            <a href="/dashboard">Trading accounts</a>
                        </div>
                    </div>
                    <div className="perks-tab">
                        <img src={tablet} alt="platform icon" style={{ height: 'auto' }} />
                        <div className="tab-heading heading">Hi-tech trades</div>
                        <div className="tab-note">
                            Trade confidently with professional analysis on a powerful platform.
                        </div>
                        <div className="tab-tag end-tag">
                            <a href="/dashbaord">Exchange</a>
                        </div>
                    </div>
                    <div className="perks-tab">
                        <img src={support_icon} alt="support icon" style={{ height: 'auto' }} />
                        <div className="tab-heading heading">Extra perks</div>
                        <div className="tab-note">
                            24/6 customer support, on-hand experts, eResources and events.
                        </div>
                        <div className="tab-tag end-tag">
                            <a href="/about">About Stack Financial</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="products no-wrap912 sec-tion">
                <div className="text-content">
                    <div className="hero-mini-tag mini-tag">ONLINE TRADING PRODUCTS</div>
                    <div role="heading" className="l1-heading heading">Hundreds of reasons to trade, now let's find yours</div>
                    <div className="subheading">
                        Explore global financial opportunities from one platform - we've got a huge range of products for you to trade on.
                    </div>
                    <div className="tab-tag end-tag">
                        <a href="/dashboard">
                            Our trading platform
                        </a>
                    </div>
                </div>
                <div className="image-content">
                    {/* Replace 'image-source.jpg' with your actual image source */}
                    <img src={trading_products} alt="Trading Platform Image" style={{ width: '100%', height: 'auto' }} />
                </div>
            </section>
            <section className="dark sec-tion">
                <div className="hero-mini-tag mini-tag">
                    24/6 SUPPORT
                </div>
                <div role="heading" className="l1-heading heading">
                    Get help from market experts in seconds
                </div>
            </section>
            <section className="quickie sec-tion">
                <div className="hero-mini-tag mini-tag">TRADING ACCOUNT</div>
                <div role="heading" className="l2-heading heading">Open a robust account in minutes</div>
                <div className="perks-tab-wrapper">
                    <div className="perks-tab">
                        <img src={step_one_open} alt="account open icon" style={{ height: 'auto' }} />
                        <div className="tab-heading heading">Step 1: Set up your account</div>
                        <div className="tab-note">
                            Start trading online in a few steps, we'll ask for your contact details to begin.
                        </div>
                        <div className="tab-tag end-tag">
                            <a href="/dashboard">Apply now</a>
                        </div>
                    </div>
                    <div className="perks-tab">
                        <img src={step_two_fund} alt="account fund icon" style={{ height: 'auto' }} />
                        <div className="tab-heading heading">Step 2: Fund easily</div>
                        <div className="tab-note">
                            Deposit and withdraw securely with credit cards, eWallets and local solutions.
                        </div>
                        <div className="tab-tag end-tag">
                            <a href="/dashboard">Ways to pay</a>
                        </div>
                    </div>
                    <div className="perks-tab">
                        <img src={step_three_trade} alt="support icon" style={{ height: 'auto' }} />
                        <div className="tab-heading heading">Step 3: Start trading</div>
                        <div className="tab-note">
                            Trade online with global client support, daily market updates & instant notifications.
                        </div>
                        <div className="tab-tag end-tag">
                            <a href="/dashboard">More on your dashboard</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="products no-wrap912 sec-tion">
                <div className="text-content">
                    <div className="hero-mini-tag mini-tag">FUND SECURELY</div>
                    <div role="heading" className="l1-heading heading">Easy money management</div>
                    <div className="subheading">
                        Deposit instantly and manage your funds effortlessly within our seamless dashboard.
                    </div>
                    <div className="tab-tag end-tag">
                        {/*
                            All available deposit methods
                        */}
                        <a href="">
                        </a>
                    </div>
                </div>
                <div className="image-content">
                    {/* Replace 'image-source.jpg' with your actual image source */}
                    <img src={transfer_funds_securely} alt="Secure Funds Transfer Image" style={{ width: '100%', height: 'auto', maxWidth: '700px' }} />
                </div>
            </section>
            <section className="platforms no-wrap912 sec-tion">
                <div className="text-content">
                    <div className="hero-mini-tag mini-tag">TRADING PLATFORM</div>
                    <div role="heading" className="l1-heading heading">Trade anywhere, the power's in your hands</div>
                    <div className="subheading">
                        Trade seamlessly across desktops, tablets, and mobile devices with a powerful global platform equipped with professional market analytics.
                    </div>
                    <div className="tab-tag end-tag">
                        <a href="/dashboard">
                            Trade now
                        </a>
                    </div>
                </div>
                <div className="image-content">
                    {/* Replace 'image-source.jpg' with your actual image source */}
                    <img src={devices} alt="Secure Funds Transfer Image" style={{ width: '100%', height: 'auto' }} />
                </div>
            </section>
            <section className="learn-more no-wrap912 sec-tion">
                <div className="text-content">
                    <div className="hero-mini-tag mini-tag">EDUCATION</div>
                    <div role="heading" className="l1-heading heading">Learn how to trade</div>
                    <div className="subheading">
                        We'll teach you how to place trades, use market charts and introduce topics like risk management and market analysis.
                    </div>
                    <div className="tab-tag end-tag">
                        <a href="/dashboard">
                            Learn more
                        </a>
                    </div>
                </div>
                <div className="image-content">
                    {/* Replace 'image-source.jpg' with your actual image source */}
                    <img src={learn_how_to_trade} alt="About Trading Image" style={{ width: '100%', height: 'auto' }} />
                </div>
            </section>
            <section className="more-to-explore sec-tion">
                <div role="heading" className="l2-heading heading">There's more to explore</div>
                <div className="perks-tab-wrapper">
                    <div className="perks-tab">
                    <img src={prefooter_security} alt="Security proof icon" style={{ height: 'auto', width: '100%' }} />
                    <div className="tab-heading heading">Extra security</div>
                    <div className="tab-note">Two-step account verification, funds kept separate & fully-regulated in most global regions.</div>
                    <div className="tab-tag end-tag">
                        <a href="/dashboard">Navigate to account</a>
                    </div>
                    </div>
                    <div className="perks-tab">
                    <img src={meet_the_team} alt="The Team" style={{ height: 'auto', width: '100%' }} />
                    <div className="tab-heading heading">Meet the team</div>
                    <div className="tab-note">Our global market experts are ready to help with support.</div>
                    <div className="tab-tag end-tag">
                        <a href="/about">
                            About Stack Financial
                        </a>
                    </div>
                    </div>
                    <div className="perks-tab">
                    <img src={all_products} alt="support icon" style={{ height: 'auto', width: '100%' }} />
                    <div className="tab-heading heading">Trading products</div>
                    <div className="tab-note">Trade CFDs on forex, crypto, commodities, indices, shares and ETFs.</div>
                    <div className="tab-tag end-tag">
                        <a href="/dashbaord">See Products</a>
                    </div>
                    </div>
                </div>
            </section>

            < FooterComponent />

            
        </div>
    );
};

export default Home;