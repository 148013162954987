import React, { useRef, useEffect } from 'react';
import small_icon_crypto from "../../component_media/small_icon_crypto.svg"
import small_nav_indices from "../../component_media/small_nav_indices.svg"
import small_nav_commodities from "../../component_media/small_nav_commodities.svg"
import small_nav_shares from "../../component_media/small_nav_shares.svg"
import small_nav_etfs from "../../component_media/small_nav_etfs.svg"
import small_crypto_cfds from "../../component_media/small_cryptocfds_icon.svg"
import small_depo_withdr_icon from "../../component_media/small_depo_withdr_icon.svg"
import small_partnership_icon from "../../component_media/small_partnership_icon.svg"
import small_referral_icon from "../../component_media/small_referral_icon.svg"

const TopBarComponent= () => {
    
    const menuBtn = useRef(null)
    const menuCloseBtn = useRef(null)
    const dropDownHead1 = useRef(null)
    const dropDownHead2 = useRef(null)
    const mobiledropDownHead1 = useRef(null)
    const mobiledropDownHead2 = useRef(null)
    const menu = useRef(null)

    useEffect(()=>{
        let dropped=false
        window.onclick=(e)=>{
            if(e.target === dropDownHead1.current || e.target === dropDownHead2.current || e.target === mobiledropDownHead2.current || e.target === mobiledropDownHead1.current){
                
                let popup=e.target.parentElement.lastElementChild
    
                if(dropped === popup){
                    popup.style.display= "none"
                    dropped = false
                }
                else{
                    if(dropped){
                        dropped.style.display= "none"
                    }
                    popup.style.display = "flex"
                    dropped= e.target.parentElement.lastElementChild
                }
            }
            else{
                if(dropped){
                    dropped.style.display= "none"
                }
                dropped = false
            }
        }
    },[]);


    const toggleMenuOpen = () =>{
        //menuBtn.classList.toggle('open');
        menuBtn.current.style.display="none"
        menuCloseBtn.current.style.display="block"
        menu.current.style.right="0px";
    }
    const toggleMenuClose = () => {
        //menuBtn.classList.toggle('open');
        menuCloseBtn.current.style.display="none"
        menuBtn.current.style.display="block"
        menu.current.style.right="-100%";
    }



    return(
        <div>
            {/*<!--Topbar-->*/}
            <div class="top-bar-wrapper">
                {/*Marqee crypto slideshow*/}
                <div id="coinmarketcap-widget-marquee" coins="1,1027,825" currency="USD" theme="light" transparent="false" show-symbol-logo="true"></div>
                <div className="home-top-bar">
                    <h1 className="home-top-header">
                        <a href="/">
                            Stack Financial
                        </a>
                    </h1>

                    {/* Nav */}
                    <div className="header-nav-links-wrapper landscape-nav">
                        <div>
                            <h4 className="dropdown-header" ref={dropDownHead1}>Products</h4>
                            <ul id="product-list" className="product-list dropdown">
                                <li>
                                    <img src={small_icon_crypto} alt="Dual Chrome Crypto icon" style={{ height: 'auto' }} />
                                    <div></div>
                                    Crypto
                                </li>
                                <li>
                                    <img src={small_nav_indices} alt="Dual Chrome Indices icon" style={{ height: 'auto' }} />
                                    <div>
                                        Indices
                                    </div>
                                </li>
                                <li>
                                    <img src={{small_nav_commodities}} alt="Dual Chrome Commodities icon" style={{ height: 'auto' }} />
                                    <div>
                                        Commodities
                                    </div>
                                </li>
                                <li>
                                    <img src={small_nav_shares} alt="Dual Chrome Shares icon" style={{ height: 'auto' }} />
                                    <div>
                                        Shares
                                    </div>
                                </li>
                                <li>
                                    <img src={small_nav_etfs} alt="Dual Chrome ETFs icon" style={{ height: 'auto' }} />
                                    <div>
                                        ETFs
                                    </div>
                                </li>
                                <li>
                                    <img src={small_crypto_cfds} alt="Dual Chrome CryptoCFDs icon" style={{ height: 'auto' }} />
                                    <div>
                                        Crypto CFDs
                                    </div>
                                </li>
                                <li>
                                    <img src={small_depo_withdr_icon} alt="Dual Chrome Deposit-withdraw icon" style={{ height: 'auto' }} />
                                    <div>
                                        Deposits & <br />
                                        withdrawals
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>Accounts</h4>
                        </div>
                        <div>
                            <h4 className="dropdown-header" ref={dropDownHead2}>
                                Partners
                            </h4>
                            <ul className="dropdown" id="partner-list">
                                <li>
                                    <img src={small_partnership_icon} alt="Referral icon" style={{ height: 'auto' }} />
                                    <div>
                                        Partner with us
                                    </div>
                                </li>
                                <li>
                                    <img src={small_referral_icon} alt="Referral icon" style={{ height: 'auto' }} />
                                    <div>
                                        Refer a friend
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>
                                <a href="/about">
                                    About Us
                                </a>
                            </h4>
                        </div>

                    </div>

                    {/* Button */}
                    <button className="butt start-now-button landscape-header-button"  aria-label="Start now">
                        <a href="/dashboard" className="contact-link" >
                            <div className="inner-butt">
                                START NOW
                            </div>
                        </a>
                    </button>
                    <div className="home-menu-btn" ref={menuBtn} onClick={toggleMenuOpen}>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="27px" height="19px" viewBox="0 0 27 19" version="1.1">
                            <title>B17E2AA5-6DB7-4566-B3CC-1F29FD895D6D</title>
                            <g id="Mobile---Client-Portal-and-IB-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="CP---New-live-acc---Dashboard" transform="translate(-16.000000, -34.000000)" fill="#4B5156" fillRule="nonzero">
                                    <g id="Burger-Menu" transform="translate(16.000000, 34.000000)">
                                        <path d="M0,1.05 C7.10171439e-17,1.62989899 0.470101013,2.1 1.05,2.1 L25.95,2.1 C26.529899,2.1 27,1.62989899 27,1.05 C27,0.470101013 26.529899,-7.72659531e-16 25.95,0 L1.05,0 C0.470101013,1.06525716e-16 -7.10171439e-17,0.470101013 0,1.05 Z M0,9.45 C7.10171439e-17,10.029899 0.470101013,10.5 1.05,10.5 L25.95,10.5 C26.529899,10.5 27,10.029899 27,9.45 C27,8.87010101 26.529899,8.4 25.95,8.4 L1.05,8.4 C0.470101013,8.4 -7.10171439e-17,8.87010101 0,9.45 Z M0,17.85 C7.10171439e-17,18.429899 0.470101013,18.9 1.05,18.9 L25.95,18.9 C26.529899,18.9 27,18.429899 27,17.85 C27,17.270101 26.529899,16.8 25.95,16.8 L1.05,16.8 C0.470101013,16.8 -7.10171439e-17,17.270101 0,17.85 Z" id="Shape" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="home-menu-close-btn" ref={menuCloseBtn} onClick={toggleMenuClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="19px" height="19px" viewBox="0 0 19 19" version="1.1">
                            <title>E9BD49D0-7067-4615-B1F5-5A05766E3EFF</title>
                            <g id="Mobile---Client-Portal-and-IB-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                <g id="CP--Open-Menu-" transform="translate(-336.000000, -34.000000)" stroke="#4B5156" strokeWidth="2">
                                    <g id="icon-12/i-remove" transform="translate(337.000000, 35.000000)">
                                        <path d="M16.2727273,0.727272727 L0.727272727,16.2727273 M0.727272727,0.727272727 L16.2727273,16.2727273" id="Combined-Shape" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                {/*<!--Mobile menu-->*/}
                <nav className="home-menu" ref={menu}>
                    <button className="butt start-now-button" aria-label="Start now">
                        <a href="/dashboard" className="contact-link" >
                            <div className="inner-butt">
                                START NOW
                            </div>
                        </a>
                    </button>
                    <div className="header-nav-links-wrapper mobile-menu">
                        <div>
                            <h4 className="dropdown-header" ref={mobiledropDownHead1}>Products</h4>
                            <ul id="product-list" className="product-list dropdown">
                                <li>
                                    <img src={small_icon_crypto} alt="Dual Chrome Crypto icon" style={{ height: "auto" }} />
                                    <div>Crypto</div>
                                </li>
                                <li>
                                    <img src={small_nav_indices} alt="Dual Chrome Indices icon" style={{ height: "auto" }} />
                                    <div>Indices</div>
                                </li>
                                <li>
                                    <img src={small_nav_commodities} alt="Dual Chrome Commodities icon" style={{ height: "auto" }} />
                                    <div>Commodities</div>
                                </li>
                                <li>
                                    <img src={small_nav_shares} alt="Dual Chrome Shares icon" style={{ height: "auto" }} />
                                    <div>Shares</div>
                                </li>
                                <li>
                                    <img src={small_nav_etfs} alt="Dual Chrome ETFs icon" style={{ height: "auto" }} />
                                    <div>ETFs</div>
                                </li>
                                <li>
                                    <img src={small_crypto_cfds} alt="Dual Chrome CryptoCFDs icon" style={{ height: "auto" }} />
                                    <div>Crypto CFDs</div>
                                </li>
                                <li>
                                    <img src={small_depo_withdr_icon} alt="Dual Chrome Deposit-withdraw icon" style={{ height: "auto" }} />
                                    <div>
                                        Deposits &amp;<br />
                                        withdrawals
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>Accounts</h4>
                        </div>
                        <div>
                            <h4 className="dropdown-header" ref={mobiledropDownHead2}>Partners</h4>
                            <ul className="dropdown" id="partner-list">
                                <li>
                                    <img src={small_partnership_icon} alt="Referral icon" style={{ height: "auto" }} />
                                    <div>Partner with us</div>
                                </li>
                                <li>
                                    <img src={small_referral_icon} alt="Referral icon" style={{ height: "auto" }} />
                                    <div>Refer a friend</div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>
                                <a href="/about">
                                        About Us
                                </a>
                            </h4>
                            </div>
                        <div>
                            <h4>
                                <a href="/support">
                                    Support
                                </a>
                            </h4>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default TopBarComponent