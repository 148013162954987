import React from 'react';
import FooterComponent from './HomePage_Sub/Footer'
import TopBarComponent from './HomePage_Sub/TopBar'
import '../components_styles/home.css'

import meet_the_team from "../component_media/meet_the_team-webp.webp"
import trusted_broker_icon from "../component_media/trusted_broker_icon.svg"
import percent_icon from "../component_media/percent.svg"
import phone_tablet from "../component_media/phone_tablet.svg"
import broker_original from "../component_media/introducing_broker.original.format-webp.webp"
import best_ecn_broker from "../component_media/Best_ECN_Broker_2023.original.png"
import most_transparent_broker from "../component_media/Most_Transparent_Broker_2023.original.png"
import best_customer_service_broker from "../component_media/Best_Customer_Service_Broker_MENA_2023.original.png"
import fastest_growing_multi_asset_broker from "../component_media/Fastest_Growing_Multi-Asset_Broker_2023.original.png"
import best_online_broker_for_crytocurrency_cfd from "../component_media/Best_Online_Broker_for_Cryptocurrency_CFDs_2023.original.png"
import network_icon from "../component_media/network_icon.svg"
import team_icon from "../component_media/team_icon.svg"
import support_icon from "../component_media/support_icon.svg"
import prefooter_security from "../component_media/prefooter_security-webp.webp"
import all_products from "../component_media/all_products-webp.webp"
import team_potraits from "../component_media/Portraits.original.format-webp.png"

const AboutComponent = () => {
    return(
        <div>
            {/*Top bar*/}
            <TopBarComponent/>
            {/*Hero section*/}
            <section className="hero-sect no-wrap912 sec-tion">
                <div className="text-content">
                    <div className="hero-mini-tag mini-tag">ABOUT STACK FINANCIAL BROKERAGE</div>
                    <h1 className="l1-heading heading">Bring your financial goals into the real world</h1>
                    <div className="subheading">
                        Stack Financial (Seychelles) is an FSA-regulated multi-asset broker offering global access for trading online.
                    </div>
                    <button className="butt start-now-button" aria-label="Start now">
                        <a href="/dashboard" className="contact-link">
                            <div className="inner-butt">START TRADING</div>
                        </a>
                    </button>
                </div>
                <div className="image-content">
                    <img
                        src={meet_the_team}
                        alt="The Team"
                        style={{ width: '100%', height: '568px' }}
                    />
                </div>
            </section>
            {/*<!--Perks section-->*/}
            <section className="perks sec-tion">
                <div className="hero-mini-tag mini-tag">MEET STACK FINANCIAL BROKERAGE</div>
                <div role="heading" className="l2-heading heading">
                    Your trusted trading partner
                </div>
                <div className="subheading about-subheading as2">
                    Our mission is to deliver financial opportunities to the world in progressive and emerging markets. Stack Financial Brokerage is licensed by the Seychelles Financial Services Authority and is a subsidiary of the Stack Financial Group.
                </div>
                <div className="perks-tab-wrapper">
                    <div className="perks-tab">
                        <img src={percent_icon} alt="percent icon" style={{ height: 'auto' }} />
                        <div className="tab-heading heading">Low rates</div>
                        <div className="tab-note">
                            Independent, deep liquidity pools with aggregated best available prices & products that allow your needs to be prioritised without bias.
                        </div>
                    </div>
                    <div className="perks-tab">
                        <img src={phone_tablet} alt="platform icon" style={{ height: 'auto' }} />
                        <div className="tab-heading heading">Hi-tech</div>
                        <div className="tab-note">
                            Access potential from global financial exchanges with the tools you need to make independent choices & kick-start your trading strategies.
                        </div>
                    </div>
                    <div className="perks-tab">
                        <img src={trusted_broker_icon} alt="Trusted broker icon" style={{ height: 'auto' }} />
                        <div className="tab-heading heading">Trusted broker</div>
                        <div className="tab-note">
                            Protecting our global clients by safeguarding private data, keeping funds segregated & refusing to merge any client assets with our business needs.
                        </div>
                    </div>
                </div>
            </section>
            {/*<!--Are you an intro?ducing broker-->*/}
            <section className="platforms no-wrap912 sec-tion">
                <div className="text-content">
                    <div className="hero-mini-tag mini-tag">JOIN FORCES WITH STACK FINANCIAL </div>
                    <div role="heading" className="l1-heading heading">Are you an Introducing Broker?</div>
                    <button className="butt start-now-button" aria-label="Start now">
                    <a href="/dashboard" className="contact-link" >
                        <div className="inner-butt">
                            PARTNER WITH US
                        </div>
                    </a>
                    </button>
                </div>
                <div className="image-content">
                    {/* Replace 'image-source.jpg' with your actual image source */}
                    <img src={broker_original} alt="Secure Funds Transfer Image" style={{ width: '100%', maxWidth: '576px', height: 'auto' }} />
                </div>
            </section>
            {/*<!--Medals section-->*/}
            <section className="perks sec-tion">
                <div className="hero-mini-tag mini-tag about-perk-section-mini-tag">STACK FINANCIAL GROUP AWARDS</div>
                <div role="heading" className="l2-heading heading about-perk-section-heading">
                    Going for golds across the globe
                </div>
                <div className="perks-tab-wrapper">
                    <div className="perks-tab about-medal-perks-tab">
                        <img
                            src={best_ecn_broker}
                            alt="Best ECN Broker 2023 medal"
                            style={{ height: 'auto' }}
                        />
                        <div className="tab-note">BEST ECN Broker - Bahrain Fintech & Crypto Summit, Feb 2023</div>
                    </div>
                    <div className="perks-tab about-medal-perks-tab">
                        <img
                            src={most_transparent_broker}
                            alt="Most Transparent Broker 2023 medal"
                            style={{ height: 'auto' }}
                        />
                        <div className="tab-note">Most Transparent Broker - Jordan Financial Expo, Mar 2022</div>
                    </div>
                    <div className="perks-tab about-medal-perks-tab">
                        <img
                            src={best_customer_service_broker}
                            alt="Best Customer Service Broker MENA 2023 medal"
                            style={{ height: 'auto' }}
                        />
                        <div className="tab-note">
                            Best Customer Service Broker MENA - Global Business Review Magazine, Jul 2022
                        </div>
                    </div>
                    <div className="perks-tab about-medal-perks-tab">
                        <img
                            src={fastest_growing_multi_asset_broker}
                            alt="Fastest Growing Multi-Asset Broker 2023 medal"
                            style={{ height: 'auto' }}
                        />
                        <div className="tab-note">Fastest Growing Multi-Asset Broker - Global Brands Magazine, Dec 2022</div>
                    </div>
                    <div className="perks-tab about-medal-perks-tab">
                        <img
                            src={best_online_broker_for_crytocurrency_cfd}
                            alt="Best Online Broker for Cryptocurrency CFDs 2023 medal"
                            style={{ height: 'auto' }}
                        />
                        <div className="tab-note">Best Online Broker for Cryptocurrency CFDs - The European, Nov 2022</div>
                    </div>
                </div>
            </section>
            {/*<!--Join our team-->*/}
            <section className="quickie sec-tion">
                <div className="hero-mini-tag mini-tag">STACK FINANCIAL GROUP</div>
                <div role="heading" className="l2-heading heading">
                    Meet our global team
                </div>
                <div className="perks-tab-wrapper">
                    <div className="perks-tab">
                        <img
                            src={network_icon}
                            alt="network icon"
                            style={{ height: 'auto' }}
                        />
                        <div className="tab-heading heading">Vast network</div>
                        <div className="tab-note">
                            Globally connected with Tier 1 banks, pro brokers, liquidity providers & financial intermediaries.
                        </div>
                    </div>
                    <div className="perks-tab">
                        <img
                            src={team_icon}
                            alt="Team icon"
                            style={{ height: 'auto' }}
                        />
                        <div className="tab-heading heading">Dedicated team</div>
                        <div className="tab-note">
                            A collective of data, product & innovation, sales, client service, business development and retail operation pros.
                        </div>
                    </div>
                    <div className="perks-tab">
                        <img
                            src={support_icon}
                            alt="support icon"
                            style={{ height: 'auto' }}
                        />
                        <div className="tab-heading heading">Multi support</div>
                        <div className="tab-note">
                            Talk to our international team from 40+ nations here to help you understand global finance, locally.
                        </div>
                    </div>
                </div>
            </section>
            {/*<!--Join our team-->*/}
            <section className="products no-wrap912 sec-tion">
                <div className="text-content">
                    <div className="hero-mini-tag mini-tag">
                    JOIN OUR TEAM
                    </div>
                    <h1 className="l1-heading heading">Grow your career at a people-first fintech</h1>
                    <p className="subheading">
                    Become a part of our rapidly expanding global family with offices in London, Dubai, Amman, Limassol & more.
                    </p>
                </div>
                <div className="image-content">
                    {/* Replace 'image-source.jpg' with your actual image source */}
                    <img
                        src={team_potraits}
                        alt="Team Portraits"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </div>
            </section>
            {/*<!--Dark stuff-->*/}
            <section className="dark sec-tion">
                <div className="hero-mini-tag mini-tag">GLOBAL BROKER</div>
                <div role="heading" className="l1-heading heading">
                    Start trading online with Stack Financial
                </div>
                <button className="button start-now-button" aria-label="Start now">
                    <a href="/dashboard" className="contact-link">
                    <div className="inner-button">START TRADING</div>
                    </a>
                </button>
            </section>
            {/*<!--More to explore-->*/}
            <section className="more-to-explore sec-tion">
                <div role="heading" className="l2-heading heading">There's more to explore</div>
                <div className="perks-tab-wrapper">
                    <div className="perks-tab">
                        <img src={prefooter_security} alt="Security proof icon" style={{ height: 'auto', width: '100%' }} />
                        <div className="tab-heading heading">Extra security</div>
                        <div className="tab-note">
                            Two-step account verification, funds kept separate & fully-regulated in most global regions.
                        </div>
                        <div className="tab-tag end-tag">
                            <a href="/dashboard">Navigate to account</a>
                        </div>
                    </div>
                    <div className="perks-tab">
                        <img src={meet_the_team} alt="The Team" style={{ height: 'auto', width: '100%' }} />
                        <div className="tab-heading heading">Meet the team</div>
                        <div className="tab-note">Our global market experts are ready to help with support.</div>
                        <div className="tab-tag end-tag">
                            <a href="">About Stack Financial</a>
                        </div>
                    </div>
                    <div className="perks-tab">
                        <img src={all_products} alt="Product icon" style={{ height: 'auto', width: '100%' }} />
                        <div className="tab-heading heading">Trading products</div>
                        <div className="tab-note">Trade CFDs on forex, crypto, commodities, indices, shares and ETFs.</div>
                        <div className="tab-tag end-tag">
                            <a href="/dashboard">See Products</a>
                        </div>
                    </div>
                </div>
            </section>
            <FooterComponent/>
        </div>
    )
}
 
export default AboutComponent